import React, { useState, useEffect } from 'react';
import SlatInput from '../../../atoms/SlatBlocks/SlatInput';
import { Props } from './interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NvInput, NvButton } from 'nv-react-components-v2';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../../store/interfaces';
import { FilteredFont, FontModel } from '../../../../store/fonts/interfaces';

const FontFamilyInput: React.FC<Props> = ({
  fontSource,
  fontFamily,
  fontImportUrl,
  actions
}): JSX.Element => {
  const dispatch = useDispatch();

  const googleFonts = useSelector(
    (state: State) => state.fonts.googleFonts,
    () => true,
  );

  const [searchValue, setSearchValue] = useState(fontFamily[0] || '');
  const [searchListOpen, setSearchListOpen] = useState(false);
  const [searchListResult, setSearchListResult] = useState<FilteredFont[]>([]);

  // update input field with selected family when it changes
  useEffect(() => {
    setSearchValue(fontFamily[0]);
  }, [fontFamily]);

  // NvInput OnChange
  const searchFont = (e: React.ChangeEvent<HTMLInputElement>) => {
    // set input field value
    setSearchValue(e.target.value);
    // show search list or not
    if (e.target.value !== '') {
      !searchListOpen && setSearchListOpen(true);
    } else {
      setSearchListOpen(false);
    }
    // match input value to google fonts i.e search
    const search = googleFonts.filter(font =>
      font.family.toLowerCase().includes(e.target.value.toLowerCase()),
    );
    // extract only font family and variants (font weights)
    const searchFilter = search.map(item => {
      return {
        family: item.family,
        variants: item.variants,
        category: item.category,
      };
    });
    setSearchListResult(searchFilter);
  };

  const selectFont = (
    item: FontModel['selectedFont'] | FontModel['selectedTabularFont'],
  ) => {
    if (item) {
      setSearchListOpen(false);
      dispatch(actions.selectFont(item));
      dispatch(actions.selectFamily([item.family]));
      dispatch(actions.setFontImportUrl());
    }
  };

  const googleFontsInput = (
    <SlatInput
      label={
        <>
          Font Family&nbsp;&nbsp;
          <a
            className={styles.browse_fonts_link}
            href="https://fonts.google.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            View all available fonts...
          </a>
        </>
      }
    >
      <div className={styles.search_list_wrapper}>
        <NvInput
          onChange={searchFont}
          value={searchValue}
          color={{
            background: 'var(--baseLight)',
            text: 'var(--copy)',
          }}
          placeholder="Search Fonts..."
        />
        <div
          className={`${styles.search_list} ${searchListOpen && styles.show}`}
        >
          {searchListResult?.map(item => (
            <button
              key={item.family}
              className={styles.search_item}
              onClick={() => {
                selectFont(item);
              }}
              type="button"
            >
              {item.family}
            </button>
          ))}
        </div>
      </div>
    </SlatInput>
  );

  const customFontInput = (
    <>
      <SlatInput label="Font Family (CSS)">
        {fontFamily.map((font, index) => {
          return (
            <div className={styles.custom_font_input_group} key={index}>
              <NvInput
                labelText={`${index + 1}`}
                required
                useBrowserValidation
                onChange={e => {
                  const f = [...fontFamily];
                  f[index] = e.target.value;
                  dispatch(actions.selectFamily(f));
                }}
                value={font}
                color={{
                  background: 'var(--baseLight)',
                  text: 'var(--copy)',
                }}
                placeholder="Open Sans"
              />
              {fontFamily.length !== 1 && (
                <NvButton
                  size="small"
                  kind="danger"
                  mini
                  leftIcon={<FontAwesomeIcon icon={faTrash} />}
                  onClick={() => {
                    const f = [...fontFamily];
                    f.splice(index, 1);
                    dispatch(actions.selectFamily(f));
                  }}
                />
              )}
            </div>
          );
        })}
        <NvButton
          className={styles.add_font_btn}
          size="small"
          mini
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
          onClick={() => {
            dispatch(actions.selectFamily([...fontFamily, '']));
          }}
        />
      </SlatInput>
      <SlatInput label="Font Import URL">
        <NvInput
          type="url"
          required
          useBrowserValidation
          onChange={e => {
            dispatch(actions.setFontImportUrl(e.target.value));
          }}
          value={fontImportUrl}
          color={{
            background: 'var(--baseLight)',
            text: 'var(--copy)',
          }}
          placeholder=""
          useBrowserValidationMessage
          reserveErrorSpace
        />
      </SlatInput>
    </>
  );

  return (
    <>
      {/* Google Fonts Inputs */}
      {fontSource === 'googlefonts' && googleFontsInput}
      {/* Custom Font Inputs */}
      {fontSource === 'customfont' && customFontInput}
    </>
  );
};
export default React.memo(FontFamilyInput);
