import React, { useEffect } from 'react';
import Slat from '../../atoms/Slat';
import SlatInput from '../../atoms/SlatBlocks/SlatInput';
import SlatHeader from '../../atoms/SlatBlocks/SlatHeader';
import SlatSection from '../../atoms/SlatBlocks/SlatSection';
import ColorField from './ColorField';
import { NvCheckbox } from 'nv-react-components-v2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeDropper } from '@fortawesome/free-solid-svg-icons';
import { Props } from './interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { colors, app } from '../../../store/actions';
import { validationModal } from '../../../helpers/validate';
import {
  modifiedColorsSelector,
  originalColorsSelector,
  validitySelector,
} from '../../../store/selectors';

const ColorSlat: React.FC<Props> = (): JSX.Element => {
  const dispatch = useDispatch();
  const {
    primary,
    secondary,
    topbarInverted,
    success,
    danger,
    warning,
    info,
  } = useSelector(modifiedColorsSelector);
  const originalColors = useSelector(originalColorsSelector);
  const { colors: isSlatValid } = useSelector(validitySelector);

  useEffect(() => {
    const valid =
      validationModal.colors.primary.test(primary) &&
      validationModal.colors.secondary.test(secondary);
    if (valid === isSlatValid) return;
    dispatch(app.updateValidity({ colors: valid }));
  }, [dispatch, isSlatValid, primary, secondary]);

  return (
    <Slat
      title="Colors"
      icon={<FontAwesomeIcon icon={faEyeDropper} />}
      cancelOnClick={() => {
        dispatch(colors.revertColors());
      }}
      isInvalid={!isSlatValid}
    >
      <SlatHeader title="Main Colors" />
      <ColorField
        label="Primary Color"
        color={primary}
        originalColor={originalColors.primary}
        action={colors.setPrimaryColor}
      />
      <ColorField
        label="Secondary Color"
        color={secondary}
        originalColor={originalColors.secondary}
        action={colors.setSecondaryColor}
      />
      <SlatHeader title="Other" />
      <SlatInput
        label="Topbar/ Login Inverted"
        tooltip="If enabled, topbar and login backgrounds will take on a white color. This is useful if your logos contain text/graphics matching your secondary color."
      >
        <NvCheckbox
          checked={topbarInverted}
          onChange={() => {
            dispatch(colors.setTopbarInverted(!topbarInverted));
          }}
          labelText={topbarInverted ? 'Enabled' : 'Disabled'}
          labelPosition="right"
        />
      </SlatInput>
      <SlatSection title="Status Colors" optional>
        <>
          <ColorField
            label="Success Color"
            color={success}
            originalColor={originalColors.success}
            action={colors.setSuccessColor}
          />
          <ColorField
            label="Danger Color"
            color={danger}
            originalColor={originalColors.danger}
            action={colors.setDangerColor}
          />
          <ColorField
            label="Warning Color"
            color={warning}
            originalColor={originalColors.warning}
            action={colors.setWarningColor}
          />
          <ColorField
            label="Info Color"
            color={info}
            originalColor={originalColors.info}
            action={colors.setInfoColor}
          />
        </>
      </SlatSection>
    </Slat>
  );
};
export default React.memo(ColorSlat);
