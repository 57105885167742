import React, { useEffect, useRef, useState } from 'react';
import { NvAlert, NvProgressBar } from 'nv-react-components-v2';
import { useSelector, useDispatch } from 'react-redux';
import { app } from '../../../store/actions';
import { Props } from './interfaces';
import styles from './styles.module.scss';
import { appSelector } from '../../../store/selectors';

const ToastContainer: React.FC<Props> = () => {
  // * This component can be rendered once at the root of the app
  // * and then it will response accordingly to the addToast action
  const dispatch = useDispatch();
  /** new toast in store */
  const { toast } = useSelector(appSelector);
  type ToastWithID = {
    id: number;
    title: string;
    description: string;
    kind?: any;
    timeout?: number;
  }[];
  /** list of toasts in state for rendering */
  const [toastList, setToastList] = useState<ToastWithID>([]);
  /** toast list ref to hold an up to date version of toast list,
   * because removeToast function only knows of toastList state at the moment it is
   * created within setTimeout*/
  const tl = useRef<ToastWithID>([]);

  useEffect(() => {
    if (toast) {
      const id = Math.random() * 100;
      const newToast = { ...toast, id };
      tl.current = [...tl.current, newToast];
      setToastList([...tl.current]);
      /** remove toast from store now that it has been added locally */
      dispatch(app.removeToast());
    }
  }, [dispatch, toast]);

  const removeToast = (id: number) => {
    tl.current = [...tl.current.filter(toast => toast.id !== id)];
    setToastList([...tl.current]);
  };

  return (
    <div
      className={`${styles.toast_wrapper} ${
        tl.current.length > 0 && styles.has_toast
      }`}
    >
      {toastList.map(toast => {
        return (
          <Toast
            key={toast.id}
            id={toast.id}
            title={toast.title}
            description={toast.description}
            kind={toast.kind}
            timeout={toast.timeout}
            removeToast={removeToast}
          />
        );
      })}
    </div>
  );
};

const Toast: React.FC<{
  id: number;
  title: string;
  description: string;
  kind: any;
  timeout?: number;
  removeToast: (id: number) => void;
}> = React.memo(
  ({ id, title, description, kind, timeout, removeToast }): JSX.Element => {
    /** toast timeout logic */
    useEffect(() => {
      if (!timeout) return;
      const timer = setTimeout(() => {
        removeToast(id);
      }, timeout);
      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div className={styles.toast}>
        <NvAlert
          inverted
          kind={kind}
          alertTitle={title}
          alertText={description}
          onClick={() => {
            removeToast(id);
          }}
        />
        {timeout && (
          <div className={styles.progress}>
            <NvProgressBar
              color="white"
              timeout={timeout}
              fullWidth
              height="1px"
            />
          </div>
        )}
      </div>
    );
  },
);

export default ToastContainer;
