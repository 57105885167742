import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Prompt, useHistory } from 'react-router-dom';
import { NvFloatingMenu, NvLoader } from 'nv-react-components-v2';
import ToastContainer from '../../atoms/Toast';
import SideNav from '../../organisms/SideNav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faEdit } from '@fortawesome/free-solid-svg-icons';
import { app } from '../../../store/actions';
import { appSelector } from '../../../store/selectors';
import { useBeforeUnload } from '../../../hooks/beforeUnload';
import { Props } from './interfaces';
import styles from './styles.module.scss';

const AppShell: React.FC<Props> = ({
  sidebar,
  content,
  dirtyGuard = false,
}): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoggedIn, activeComponent } = useSelector(appSelector);
  useEffect(() => {
    // if user navigates directly to this component i.e. /app
    // check if they are logged in
    // else route to /login
    if (!isLoggedIn) {
      history.push('/login?rurl=' + window.location.href);
    }
  }, [history, isLoggedIn, dispatch]);

  useBeforeUnload(dirtyGuard);

  return (
    <div className={styles.app}>
      {isLoggedIn ? (
        // {isLoggedIn && resellerIds.length > 0 ? ( // * for when we can fetch a list of available resellers
        <>
          <SideNav />
          <div
            className={[
              styles.sidebar_wrapper,
              activeComponent === 'sidebar' ? styles.show : '',
            ].join(' ')}
          >
            {sidebar}
          </div>
          <div
            className={[
              styles.content,
              activeComponent === 'preview' ? styles.show : '',
            ].join(' ')}
          >
            {content}
          </div>
          <NvFloatingMenu
            className={styles.mobile_nav}
            buttons={[
              {
                content: <FontAwesomeIcon icon={faEdit} />,
                value: 'sidebar',
                onClick: () => {
                  dispatch(app.setActiveComponent('sidebar'));
                },
              },
              {
                content: <FontAwesomeIcon icon={faDesktop} />,
                value: 'preview',
                onClick: () => {
                  dispatch(app.setActiveComponent('preview'));
                },
              },
            ]}
            value={activeComponent}
            closeOnClickOutside
            color={{
              buttonBackground: 'var(--baseDark)',
              text: 'var(--copy)',
              buttonBorder: 'var(--shadowDark)',
            }}
          />
          <ToastContainer />
          <Prompt
            when={dirtyGuard}
            message="Changes that you made may not be saved."
          />
        </>
      ) : (
        <div className={styles.loader}>
          <NvLoader />
        </div>
      )}
    </div>
  );
};

export default AppShell;
