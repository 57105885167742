import { useEffect } from 'react';

export const useBeforeUnload = (shouldShowMessage: boolean) => {
  /**
   * Window beforeunload event
   */
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (!shouldShowMessage) return;
      const confirmationMessage = 'Any unsaved changes will be lost';
      e.preventDefault();
      (e || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [shouldShowMessage]);
};
