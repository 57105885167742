import React from 'react';
import styles from './styles.module.scss';
import { Props } from './interfaces';
import Tooltip from '../../Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const SlatHeader: React.FC<Props> = ({
  title,
  children,
  tooltip,
}): JSX.Element => {
  return (
    <>
      <div className={styles.slat_header}>
        <span>
          {title}
          {tooltip && (
            <Tooltip content={tooltip} >
              <div className={styles.tooltip}>
                <FontAwesomeIcon icon={faQuestionCircle} />
              </div>
            </Tooltip>
          )}
        </span>
        {children}
      </div>
    </>
  );
};
export default React.memo(SlatHeader);
