import { links } from '../actionTypes';
import { LinkState } from './interfaces';

export const setLinks = (updatedLinks: Partial<LinkState['original']>) => {
  return {
    type: links.setLinks,
    payload: {
      ...updatedLinks,
    },
  };
};
export const revertLinks = () => {
  return {
    type: links.revertLinks,
  };
};

