import React, { useState } from 'react';
import SlatHeader from '../SlatHeader';
import { Props } from './interfaces';
import { NvCheckbox } from 'nv-react-components-v2';

const SlatSection: React.FC<Props> = ({
  title,
  optional = false,
  children,
  tooltip,
}): JSX.Element => {
  const [showOptional, setShowOptional] = useState(false);
  return (
    <>
      <SlatHeader title={title} tooltip={tooltip}>
        {optional && (
          <NvCheckbox
            kind="toggle"
            size="small"
            checked={showOptional}
            onChange={() => {
              setShowOptional(!showOptional);
            }}
          />
        )}
      </SlatHeader>
      {showOptional && optional && children}
    </>
  );
};
export default React.memo(SlatSection);
