import React, { useEffect } from 'react';
import BrandingSidebar from '../../organisms/BrandingSidebar';
import LivePreview from '../../organisms/LivePreview';
import ProductTour from '../../organisms/ProductTour';
import { useSelector, useDispatch } from 'react-redux';
import { app } from '../../../store/actions';
import setTitle from '../../../helpers/setTitle';
import AppShell from '../../templates/AppShell';
import { appSelector, brandingIsDirtySelector } from '../../../store/selectors';
// import styles from './styles.module.scss';

const BrandingPage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const { currentReseller } = useSelector(appSelector);
  const brandingDirty = useSelector(brandingIsDirtySelector);

  useEffect(() => {
    setTitle('Customise Branding');
    if (currentReseller === -1) return;
    dispatch(app.fetchBranding());
  }, [dispatch, currentReseller]);

  return (
    <>
      <AppShell
        sidebar={<BrandingSidebar />}
        content={<LivePreview />}
        dirtyGuard={brandingDirty}
      />
      <ProductTour />
    </>
  );
};

export default BrandingPage;
