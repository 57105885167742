import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tooltips } from '../../../store/actions';

import Tabs from '../../atoms/Tabs';
import { tooltipFrame } from '../../../helpers/tooltipFrame';
import styles from './styles.module.scss';
import { appSelector, tooltipStateSelector } from '../../../store/selectors';

const appUrlMap: { [appname: string]: string } = {
  manager: 'https://app.preview.sipcentric.com/login',
  analytics: 'https://app.preview.sipcentric.com/apps/analytics/login',
  communicator: 'https://app.preview.sipcentric.com/apps/phone',
};

const TooltipAppFrame = () => {
  const dispatch = useDispatch();
  const { activeApp } = useSelector(tooltipStateSelector);
  const { currentReseller, accessToken, tokenExpires } = useSelector(
    appSelector,
  );
  const appUrl = appUrlMap[activeApp];
  tooltipFrame.appUrl = appUrl;

  const params = new URLSearchParams({
    partnerId: String(currentReseller === -1 ? 0 : currentReseller),
    accessToken,
    expires: tokenExpires,
  }).toString();
  return (
    <article className={styles.tooltip_app_frame_container}>
      <Tabs
        tabs={['manager' /* 'analytics', 'communicator'*/]}
        onTabChange={tab => {
          dispatch(tooltips.changeTooltipApp(tab));
        }}
        selectedTab={activeApp}
      />
      <iframe
        title={appUrl}
        className={styles.tooltip_app_frame}
        src={`${appUrl}?${params}`}
      ></iframe>
    </article>
  );
};
export default TooltipAppFrame;
