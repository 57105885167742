import { AppState } from './interfaces';
import { app, root } from '../actionTypes';
import { Dispatch } from 'redux';
import mapBranding from '../../helpers/mapBranding';
import { State } from '../interfaces';
import { BRANDING_API } from '../../helpers/constants';

/*
 * Login Logout
 */

export const login = () => {
  return {
    type: app.login,
    payload: {
      isLoggedIn: true,
    },
  };
};

export const logout = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: app.logout,
      payload: {
        isLoggedIn: false,
      },
    });
    dispatch({
      type: root.resetState,
    });
  };
};
/*
 * Set user account variables
 */
export const setUserName = (userName: AppState['userName']) => {
  return { type: app.setUserName, payload: { userName } };
};
export const setUserAccessLevel = (
  userAccessLevel: AppState['userAccessLevel'],
) => {
  return {
    type: app.setUserAccessLevel,
    payload: { userAccessLevel },
  };
};

export const setAccessToken = (accessToken: AppState['accessToken']) => {
  return {
    type: app.setAccessToken,
    payload: { accessToken },
  };
};

export const setTokenExpires = (tokenExpires: AppState['tokenExpires']) => {
  return {
    type: app.setTokenExpires,
    payload: { tokenExpires },
  };
};

export const setResellerIds = (
  resellerId: number,
  accessToken: string = '',
) => {
  return async (dispatch: Dispatch) => {
    let resellerIdArray: number[] = [];
    if (resellerId) {
      resellerIdArray = [resellerId];
    } else {
      //do a fetch here for the reseller array (SUPPORT_L2, ADMIN) since their rid will be falsey
      const availableResellers = fetchAvailableResellers(accessToken);
      resellerIdArray = [...availableResellers];
    }
    dispatch({
      type: app.setResellerIds,
      payload: {
        resellerIds: resellerIdArray,
      },
    });
  };
};

// thunk action for fetching resellers available to this account
const fetchAvailableResellers = (accessToken: string) => {
  // TODO this should fetch available reseller ids for SUPPORT_L2 and ADMIN accounts
  // const apiBase = 'https://pbx.sipcentric.com/api/v1/';
  // return async (dispatch: Dispatch) => {
  //   const response = await fetch(`${apiBase}customers`, {
  //     headers: {
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //   });
  //   const responseJson = await response.json();
  //   const availableAccounts = responseJson.items;
  //   dispatch({
  //     type: app.fetchAvailableCustomers,
  //     payload: { availableAccounts },
  //   });
  // once we have fetched the customer list, populate interface with first one
  // fetchBranding(availableAccounts[0].id)(dispatch);
  return [];
};

export const setCurrentReseller = (
  currentReseller: AppState['currentReseller'],
) => {
  return {
    type: app.setCurrentReseller,
    payload: { currentReseller },
  };
};

/**
 * Toast
 */
export const addToast = (toast: AppState['toast']) => {
  return {
    type: app.addToast,
    payload: {
      toast,
    },
  };
};
export const removeToast = () => {
  return {
    type: app.removeToast,
  };
};

/*
 * Fetch branding from branding api
 */

export const fetchBranding = () => {
  return async (dispatch: Dispatch, getState: () => State) => {
    const { currentReseller } = getState().app;
    if (currentReseller < 1) {
      return dispatch(
        addToast({
          title: 'Invalid Reseller ID',
          description: 'Value of Reseller ID must be > 0',
          kind: 'warning',
          timeout: 5000,
        }),
      );
    }

    dispatch(brandingRequested());
    let fetchedBranding;
    try {
      const branding = await fetch(BRANDING_API.BRANDING, {
        method: 'GET',
        headers: {
          'X-Partner-Id': `${currentReseller}`,
        },
      });
      const response = await branding.json();
      if (response.code >= 400) {
        throw new Error(`${response?.message} - ${response?.name}`);
      }
      fetchedBranding = response.data;
      dispatch({
        type: root.populateFields,
        payload: { fetchedBranding },
      });
    } catch (err) {
      dispatch(
        addToast({
          title: 'Connection Error',
          description: `Looks like we couldn't connect you to our servers [${
            (err as Error).message
          }]`,
          kind: 'danger',
          timeout: 5000,
        }),
      );
    }
    dispatch(brandingLoaded());
  };
};

const brandingRequested = () => {
  return {
    type: app.brandingRequested,
  };
};
const brandingLoaded = () => {
  return {
    type: app.brandingLoaded,
  };
};

/**
 * Publish changes to branding api
 */
export const publishChanges = () => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(publishingChanges());
    const state = getState();
    const { accessToken, currentReseller } = state.app;

    let toast: {
      title: string;
      description: string;
      kind: 'danger' | 'warning' | 'success' | 'info' | undefined;
      timeout: number;
    } = {
      title: 'Publishing Error',
      description: '',
      kind: 'danger',
      timeout: 5000,
    };
    if (currentReseller === -1) return dispatch(publishingDone());
    try {
      // * upload logos
      for (let logoType in state.logos.modified) {
        const param = `?scope=${logoType}`;
        if (state.logos.modified[logoType].logoFormData) {
          const logo = await fetch(`${BRANDING_API.UPLOAD}${param}`, {
            method: 'POST',
            body: state.logos.modified[logoType].logoFormData,
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'X-Partner-Id': `${currentReseller}`,
            },
          });
          const logoRes = await logo.json();
          if (logoRes?.code >= 400) {
            throw new Error(`${logoRes?.message} ${logoRes?.name}`);
          }
        }
      }
      // * map data for API
      const stateToPublish = mapBranding.storeToAPI(state);
      // * logos uploaded, try publishing
      try {
        const publish = await fetch(BRANDING_API.BRANDING, {
          method: 'PUT',
          body: JSON.stringify(stateToPublish),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'X-Partner-Id': `${currentReseller}`,
          },
        });
        const publishRes = await publish.json();
        if (publishRes?.code >= 400) {
          throw new Error(`${publishRes?.message} - ${publishRes?.name}`);
        }
        // dispatch({ type: root.saveChanges });
        dispatch({
          type: root.populateFields,
          payload: { fetchedBranding: publishRes.data },
        });
        toast = {
          ...toast,
          title: 'Publish Complete',
          description: 'Successfully published branding',
          kind: 'success',
          timeout: 3000,
        };
      } catch ({ message }) {
        // * catch failed publish
        toast = {
          ...toast,
          description: `Looks like there was an issue publishing your branding [${message}]`,
        };
      }
    } catch ({ message }) {
      // * catch failed logo upload
      toast = {
        ...toast,
        description: `Logo upload failed [${message}]`,
      };
    } finally {
      dispatch(addToast(toast));
      dispatch(publishingDone());
    }
  };
};

const publishingChanges = () => {
  return {
    type: app.publishingChanges,
  };
};
const publishingDone = () => {
  return {
    type: app.publishingDone,
  };
};

export const updateValidity = (validity: Partial<AppState['validity']>) => {
  return {
    type: app.updateValidity,
    payload: {
      validity,
    },
  };
};

export const setActiveComponent = (component: AppState['activeComponent']) => {
  return {
    type: app.setActiveComponent,
    payload: {
      activeComponent: component,
    },
  };
};
