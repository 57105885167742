import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import {
  Manager,
  Login,
  Analytics,
  Communicator,
  Verticall,
} from '../../molecules/Mockups';
import Overview from './Overview';
import Tabs from '../../atoms/Tabs';
import { useSelector } from 'react-redux';
import { State } from '../../../store/interfaces';
import { fontArrayToString } from '../../../helpers/fonts';
import {
  modifiedColorsSelector,
  modifiedFontsSelector,
} from '../../../store/selectors';

const LivePreview: React.FC = (): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState('overview');

  const colors = useSelector(modifiedColorsSelector);
  const fonts = useSelector(modifiedFontsSelector);

  const apps = useSelector((state: State) =>
    Object.keys(state.icons.original).sort(),
  );

  useEffect(() => {
    // dynamically import font url when user changes font
    if (fonts.fontFamily.length !== 0) {
      const oldFontStyle = document.getElementById('importedFont');
      if (oldFontStyle?.getAttribute('href') === fonts.fontImportUrl) {
        // font import url hasnt changed, no need to reload
        return;
      }
      const fontStyle = document.createElement('link');
      fontStyle.id = 'importedFont';
      fontStyle.setAttribute('href', fonts.fontImportUrl);
      fontStyle.setAttribute('rel', 'stylesheet');
      document.head.appendChild(fontStyle);
      oldFontStyle?.parentNode?.removeChild(oldFontStyle);
    }
  }, [fonts]);

  return (
    <div className={styles.livepreview_container} id="product-tour-preview">
      <nav className={styles.livepreview_nav} id="product-tour-mockup">
        <Tabs
          tabs={['overview', 'login', ...apps, 'verticall']}
          onTabChange={setSelectedTab}
          selectedTab={selectedTab}
        />
      </nav>
      <div
        className={`${styles.preview}`}
        id={styles.preview}
        style={
          {
            fontFamily: `${fontArrayToString(
              fonts.fontFamily,
            )}, 'Open Sans', sans-serif`,
            '--font-weight-light': fonts.fontWeights.light,
            '--font-weight-regular': fonts.fontWeights.regular,
            '--font-weight-semibold': fonts.fontWeights.semibold,
            '--font-weight-bold': fonts.fontWeights.bold,
            '--primary': colors.primary || '#003049',
            '--secondary': colors.secondary || '#777777',
          } as React.CSSProperties
        }
      >
        {selectedTab === 'overview' && <Overview />}
        {selectedTab === 'login' && <Login />}
        {selectedTab === 'manager' && <Manager />}
        {selectedTab === 'analytics' && <Analytics />}
        {selectedTab === 'communicator' && <Communicator />}
        {selectedTab === 'verticall' && <Verticall />}
      </div>
    </div>
  );
};

export default React.memo(LivePreview);
