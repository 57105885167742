import chroma from 'chroma-js';
import { ColorState } from '../store/colors/interfaces';
// copied from react-native-softphone https://github.com/sipcentric/react-native-softphone/blob/4d5ae3fd4741279c4fa5497c97cb23fa4be8b408/src/styles/base.ts
const baseColors = {
  primary: '#2679ff',
  error: '#ff390c',
  warning: '#ffc311',
  skyAtNight: '#314755',
  darkishSkyAtNight: '#233549',
  darkSkyAtNight: '#14233c',
  dark: '#233441',
  skyBlue: '#26a0da',
  paleLilac: '#e3e3e4',
  coolGrey: '#9da5ac',
  coolerGrey: '#6A7279',
  white: '#ffffff',
  slimyGoo: '#00e99f',
  bloodyPink: '#ff465c',
  amberParty: '#f49842',
  transparent: 'transparent',
};

// Copied from react-native-softphone https://github.com/sipcentric/react-native-softphone/blob/4d5ae3fd4741279c4fa5497c97cb23fa4be8b408/src/selectors/branding.ts
export const generateVerticallColors = (colors: ColorState['modified']) => {
  const invertTextColor = !!colors.topbarInverted;
  const primaryTextColor = invertTextColor ? '#555' : baseColors.paleLilac;
  const colorPrimary = colors?.primary || baseColors.skyBlue;
  const colorSecondary = invertTextColor
    ? '#FEFEFE'
    : colors.secondary || baseColors.skyAtNight;
  const colorSuccess = colors.success || baseColors.slimyGoo;
  const colorDanger = colors.danger || baseColors.bloodyPink;
  // const colorInfo = brandingInfo.branding?.colorInfo || 'yellow';
  const colorWarning = colors.warning || baseColors.amberParty;

  const darkishSkyAtNight = chroma(colorSecondary).darken(0.7).hex('rgb');
  const darkSkyAtNight = chroma(colorSecondary).darken(1.1).hex('rgb');
  const dark = chroma(colorSecondary).darken(0.9).hex('rgb');

  const bgDefault = invertTextColor ? colorSecondary : darkishSkyAtNight;
  // const bgGradientStart = invertTextColor ? '#FEFEFE' : colorSecondary;
  const bgGradientEnd = invertTextColor ? colorSecondary : darkSkyAtNight;

  const searchBarBg = invertTextColor ? '#E2E2E2' : dark;

  return {
    primaryTextColor,
    secondaryTextColor: baseColors.coolGrey,
    colorPrimary,
    colorSecondary,
    colorSuccess,
    colorDanger,
    colorWarning,
    bgDefault,
    bgGradientStart: colorSecondary,
    bgGradientEnd,
    dark,
    searchBarBg,
  };
};
