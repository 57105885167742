import { LinkState, LinkAction } from './interfaces';
import { links } from '../actionTypes';
import { Reducer } from 'redux';

const setLinks = (state: LinkState, payload: LinkAction['payload']) => {
  return {
    ...state,
    modified: {
      ...state.modified,
      ...payload,
    },
  };
};
const revertLinks = (state: LinkState) => {
  return {
    ...state,
    modified: { ...state.original },
  };
};

export const initialLinkState: LinkState = {
  original: {
    supportEmail: '',
    supportPhone: '',
    termsUrl: '',
    refundsUrl: '',
    contactsUrl: '',
    helpUrl: '',
    twitter: '',
    twitterUrl: '',
  },
  modified: {
    supportEmail: '',
    supportPhone: '',
    termsUrl: '',
    refundsUrl: '',
    contactsUrl: '',
    helpUrl: '',
    twitter: '',
    twitterUrl: '',
  },
};
const reducer: Reducer<LinkState, LinkAction> = (
  state: LinkState = initialLinkState,
  action: LinkAction,
) => {
  if (!action) {
    return { ...{}, ...state };
  }
  switch (action.type) {
    case links.setLinks:
      return setLinks(state, action.payload);
    case links.revertLinks:
      return revertLinks(state);
    default:
      return { ...state };
  }
};
export default reducer;
