import React, { useEffect, useState } from 'react';
import { NvInput } from 'nv-react-components-v2';
import SlatInput from '../../../atoms/SlatBlocks/SlatInput';
import { Props } from './interfaces';
import styles from './styles.module.scss';
import chroma from 'chroma-js';
import { useDispatch } from 'react-redux';

const ColorField: React.FC<Props> = ({
  color: colorInStore,
  label,
  action,
  originalColor,
}): JSX.Element => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(colorInStore);
  const [showError, setShowError] = useState(!chroma.valid(colorInStore));

  useEffect(() => {
    // if we have reverted, then reset local state
    if (originalColor === colorInStore) {
      setValue(originalColor);
      setShowError(!chroma.valid(colorInStore))
    }
  }, [colorInStore, originalColor]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // control local input state
    const color = e.target.value;
    setValue(color);
    // set errors
    const isValidColor = chroma.valid(color);
    setShowError(!isValidColor);

    // if we have valid color then dispatch hex value to store
    if (isValidColor) {
      const hexValue = chroma(color).hex();
      const hexColorInStore = chroma.valid(colorInStore)
        ? chroma(colorInStore)
        : '';
      const isSameColorAsStore = hexValue === hexColorInStore;
      !isSameColorAsStore && dispatch(action(hexValue));
    }
  };

  return (
    <SlatInput label={label}>
      <div className={styles.input_textcolor_group}>
        <NvInput
          value={value}
          onChange={onChange}
          color={{
            background: 'var(--baseLight)',
            text: 'var(--copy)',
            borderFocus: colorInStore,
            borderBlur: colorInStore,
          }}
          validationError={showError ? 'Expected Hex, RGB, CSS Colors' : ''}
          placeholder={'#FFFFFF'}
          required
          useBrowserValidation
          reserveErrorSpace
        />
        <NvInput
          type="color"
          value={colorInStore}
          onChange={e => {
            dispatch(action(e.target.value));
            setValue(e.target.value);
          }}
          color={{
            background: 'var(--baseLight)',
            text: 'var(--copy)',
          }}
        />
      </div>
    </SlatInput>
  );
};
export default React.memo(ColorField);
