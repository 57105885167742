import { BrandState, BrandAction } from './interfaces';
import { brand } from '../actionTypes';
import { Reducer } from 'redux';

const setBrandName = (state: BrandState, payload: BrandAction['payload']) => {
  return {
    ...state,
    modified: {
      ...state.modified,
      brandName: payload.brandName ?? state.modified.brandName,
    },
  };
};
const setWebsite = (state: BrandState, payload: BrandAction['payload']) => {
  return {
    ...state,
    modified: {
      ...state.modified,
      website: payload.website ?? state.modified.website,
    },
  };
};

const revertBrand = (state: BrandState) => {
  return {
    ...state,
    modified: { ...state.original },
  };
};


export const initialBrandState: BrandState = {
  original: { brandName: '', website: '', },
  modified: {
    brandName: '',
    website: '',
  },
};

const reducer: Reducer<BrandState, BrandAction> = (
  state: BrandState = initialBrandState,
  action: BrandAction,
) => {
  if (!action) {
    return { ...{}, ...state };
  }
  switch (action.type) {
    case brand.setBrandName:
      return setBrandName(state, action.payload);
    case brand.setWebsite:
      return setWebsite(state, action.payload);
    case brand.revertBrand:
      return revertBrand(state);
    default:
      return { ...state };
  }
};
export default reducer;
