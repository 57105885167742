import React, { useState } from 'react';
import TopBar from '../TopBar';
import DeviceEmulator from '../../DeviceEmulator';
import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGlobe,
  faHome,
  faLink,
  faPhoneAlt,
} from '@fortawesome/free-solid-svg-icons';
const dashboard = (
  <>
    <div className={styles.manager_title}>
      <h1>Dashboard</h1>
      <span className={styles.primary}>This user </span>
      <span>is the primary contact for this account</span>
    </div>
    <div className={styles.manager_dashboard}>
      {[...Array(9)].map((_, i) => (
        <div
          className={styles.manager_dashboard_item}
          key={`manager_dashboard_item_${i}`}
        >
          <h4 className={styles.primary}>Dashboard Item {i + 1}</h4>
          <div className={styles.manager_dashboard_item_content}>
            <h3>{`£${(Math.random() * 100) | 0}.00`}</h3>
            <p className={styles.primary}>UNBILLED CALLS</p>
          </div>
        </div>
      ))}
    </div>
  </>
);
const incomingNumbers = (
  <>
    <div className={styles.manager_title}>
      <h1>Incoming Numbers</h1>
    </div>
    <div className={styles.manager_slatlist}>
      {[...Array(10)].map((_, i) => {
        return (
          <div className={styles.manager_slat} key={i}>
            <FontAwesomeIcon icon={faPhoneAlt} />
            <span className={styles.number}>
              0121{(Math.random() * 1000000).toFixed(0)}
            </span>
            <span className={styles.tag}>tag</span>
          </div>
        );
      })}
    </div>
  </>
);
const trunks = (
  <>
    <div className={styles.manager_title}>
      <h1>Trunks</h1>
    </div>
    <div className={styles.manager_slatlist}>
      {[...Array(3)].map((_, i) => {
        return (
          <div className={styles.manager_slat} key={i}>
            <FontAwesomeIcon icon={faLink} />
            <span className={styles.number}>
              {(Math.random() * 1000).toFixed(0)}
            </span>
            <span>Trunk {i}</span>
          </div>
        );
      })}
    </div>
  </>
);

const Manager: React.FC = (): JSX.Element => {
  const [selectedPage, setSelectedPage] = useState(0);
  const [showHamburger, setShowHamburger] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const pages = [
    {
      label: 'Dashboard',
      icon: faHome,
      content: dashboard,
    },
    {
      label: 'Incoming Numbers',
      icon: faGlobe,
      content: incomingNumbers,
    },
    {
      label: 'Trunks',
      icon: faLink,
      content: trunks,
    },
  ];

  const sidebar = pages.map(({ label, icon }, index) => {
    return (
      <div
        key={index}
        className={`${styles.manager_sidebar_item} ${
          index === selectedPage && styles.manager_sidebar_item_active
        }`}
        onClick={() => {
          setSelectedPage(index);
          setShowSidebar(false);
        }}
      >
        <FontAwesomeIcon icon={icon} />
        {label}
      </div>
    );
  });
  return (
    <DeviceEmulator
      className={styles.device}
      onBreakpointChange={breakpointType => {
        setShowHamburger(breakpointType === 'xs' || breakpointType === 'sm');
      }}
    >
      <div className={styles.manager_app}>
        <TopBar
          appName="manager"
          showHamburger={showHamburger}
          onHamburgerClick={() => {
            setShowSidebar(!showSidebar);
          }}
        />
        <div
          className={[
            styles.manager_sidebar,
            showSidebar ? styles.show : '',
          ].join(' ')}
        >
          {sidebar}
        </div>
        <div className={styles.manager_content}>
          {pages[selectedPage].content}
        </div>
      </div>
    </DeviceEmulator>
  );
};

export default Manager;
