import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTh,
  faClock,
  faUserFriends,
} from '@fortawesome/free-solid-svg-icons';
import DeviceEmulator from '../../DeviceEmulator/component';
import DialPad from './DialPad';
import Contacts from './Contacts';
import Calls from './Calls';
import { modifiedColorsSelector } from '../../../../store/selectors';
import { generateVerticallColors } from '../../../../helpers/verticall';
import styles from './styles.module.scss';

const Verticall = (): JSX.Element => {
  const colors = useSelector(modifiedColorsSelector);
  const verticallColors = generateVerticallColors(colors);
  const tabs = {
    phone: {
      label: 'Phone',
      icon: faTh,
      component: <DialPad />,
    },
    calls: {
      label: 'Calls',
      icon: faClock,
      component: <Calls />,
    },
    contacts: {
      label: 'Contacts',
      icon: faUserFriends,
      component: <Contacts />,
    },
  };
  const [selectedTab, setSelectedTab] = React.useState<keyof typeof tabs>(
    'phone',
  );

  return (
    <DeviceEmulator className={styles.device}>
      <article
        className={styles.app}
        style={
          {
            '--vc-primary': verticallColors.colorPrimary,
            '--vc-secondary': verticallColors.colorSecondary,
            '--vc-bg-gradient-start': verticallColors.bgGradientStart,
            '--vc-bg-gradient-end': verticallColors.bgGradientEnd,
            '--vc-primary-text': verticallColors.primaryTextColor,
            '--vc-secondary-text': verticallColors.secondaryTextColor,
            '--vc-search-bar-bg': verticallColors.searchBarBg,
            '--vc-dark': verticallColors.dark,
          } as React.CSSProperties
        }
      >
        {tabs[selectedTab].component}
        <nav className={styles.nav}>
          {Object.entries(tabs).map(([tab, { label, icon }]) => {
            return (
              <button
                key={tab}
                className={selectedTab === tab ? styles.selected : ''}
                onClick={() => setSelectedTab(tab as any)}
              >
                <span>
                  <FontAwesomeIcon icon={icon} />
                </span>
                <span>{label}</span>
              </button>
            );
          })}
        </nav>
      </article>
    </DeviceEmulator>
  );
};

export default Verticall;
