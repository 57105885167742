import React from 'react';
import { Props } from './interfaces';
import './styles.scss';
import Tippy from '@tippyjs/react';

const Tooltip: React.FC<Props> = ({ children, content, ...tippyProps }) => {
  return (
    <Tippy content={content} theme="custom" {...tippyProps} arrow={false}>
      <span>{children}</span>
    </Tippy>
  );
};

export default Tooltip;
