import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import './index.css';
import Login from './components/pages/Login';
import Logout from './components/pages/Logout';
import BrandingPage from './components/pages/BrandingPage';
import TooltipPage from './components/pages/TooltipPage';
import PageNotFound from './components/pages/PageNotFound';
import { configureStore } from './store/configureStore';
import { Provider } from 'react-redux';

import 'tippy.js/dist/tippy.css';

console.log(
  '%c@haydonlam',
  'color:black;background:#E3E3E3;font-family:Poppins, Arial, sans-serif;padding:3px',
);

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router>
        <Switch>
          <Route path="/logout">
            <Logout />
          </Route>
          <Route path="/login" exact>
            <Login />
          </Route>
          <Route path="/app">
            <BrandingPage />
          </Route>
          <Route path="/tooltip">
            <TooltipPage />
          </Route>
          <Redirect from="/" to="/login" exact />
          <Route path="/">
            <PageNotFound />
          </Route>
        </Switch>
      </Router>
    </React.StrictMode>
  </Provider>,

  document.getElementById('root'),
);
