import React, { useState, useEffect } from 'react';
import Slat from '../../atoms/Slat';
import SlatHeader from '../../atoms/SlatBlocks/SlatHeader';
import SlatSection from '../../atoms/SlatBlocks/SlatSection';
import { Props } from './interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFont } from '@fortawesome/free-solid-svg-icons';
import { NvRadioGroup } from 'nv-react-components-v2';
import FontWeightSelect from './FontWeightSelect';
import FontFamilyInput from './FontFamilyInput';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../store/interfaces';
import { fonts, app } from '../../../store/actions';
import { validationModal } from '../../../helpers/validate';
import { modifiedFontsSelector, validitySelector } from '../../../store/selectors';

const FontSlat: React.FC<Props> = (): JSX.Element => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fonts.fetchGoogleFonts());
  }, [dispatch]);

  const {
    selectedFont,
    fontFamily,
    fontImportUrl,
    tabularFontFamily,
    tabularFontImportUrl,
  } = useSelector(modifiedFontsSelector);

  const initialFontImportUrl = useSelector(
    (state: State) => state.fonts.original.fontImportUrl,
  );
  const initialTabularFontImportUrl = useSelector(
    (state: State) => state.fonts.original.tabularFontImportUrl,
  );

  const { fonts: isSlatValid } = useSelector(validitySelector);

  // this is needed so that the component accurately represents fontWeight changes in the store
  // despite fontWeights not being used directly, its value is changed by this component
  useSelector((state: State) => state.fonts.modified.fontWeights);

  const [fontSource, setFontSource] = useState('googlefonts');
  const [tabularFontSource, setTabularFontSource] = useState('googlefonts');

  /**
   * Validation
   */
  useEffect(() => {
    let valid;
    if (fontSource === 'googlefonts') {
      valid = validationModal.fonts.fontFamily.test(fontFamily[0]);
    }
    if (fontSource === 'customfont') {
      valid =
        validationModal.fonts.fontImportUrl.test(fontImportUrl) &&
        fontFamily.every(font => validationModal.fonts.fontFamily.test(font));
    }
    if (valid === isSlatValid) return;
    dispatch(app.updateValidity({ fonts: valid }));
  }, [dispatch, fontFamily, fontImportUrl, fontSource, isSlatValid]);

  // check if font import url is google font, update font source accordingly
  useEffect(() => {
    if (initialFontImportUrl) {
      const isGoogleFont = initialFontImportUrl.includes(
        'fonts.googleapis.com',
      );
      setFontSource(isGoogleFont ? 'googlefonts' : 'customfont');
    }
    if (initialTabularFontImportUrl) {
      const isGoogleFont = initialTabularFontImportUrl.includes(
        'fonts.googleapis.com',
      );
      setTabularFontSource(isGoogleFont ? 'googlefonts' : 'customfont');
    }
  }, [initialFontImportUrl, initialTabularFontImportUrl]);

  return (
    <Slat
      title="Fonts"
      icon={<FontAwesomeIcon icon={faFont} />}
      cancelOnClick={() => {
        dispatch(fonts.revertFonts());
      }}
      isInvalid={!isSlatValid}
    >
      <SlatHeader title="Font Family" />
      <div className={styles.radio_group_wrapper}>
        <NvRadioGroup
          options={[
            { label: 'Google Fonts', value: 'googlefonts' },
            { label: 'Custom Font', value: 'customfont' },
          ]}
          value={fontSource}
          side
          size="small"
          onChange={setFontSource}
          name="fontsource"
          fullWidth
        />
      </div>
      <FontFamilyInput
        fontSource={fontSource}
        fontFamily={fontFamily}
        fontImportUrl={fontImportUrl}
        actions={{
          selectFont: fonts.selectFont,
          selectFamily: fonts.selectFamily,
          setFontImportUrl: fonts.setFontImportUrl,
        }}
      />
      <SlatSection
        title="Tabular Font Family"
        optional
        tooltip="Font used for numbers displayed in data tables (optional)"
      >
        <div className={styles.radio_group_wrapper}>
          <NvRadioGroup
            options={[
              { label: 'Google Fonts', value: 'googlefonts' },
              { label: 'Custom Font', value: 'customfont' },
            ]}
            value={tabularFontSource}
            side
            size="small"
            onChange={setTabularFontSource}
            name="tabularfontsource"
            fullWidth
          />
        </div>
        <FontFamilyInput
          fontSource={tabularFontSource}
          fontFamily={tabularFontFamily}
          fontImportUrl={tabularFontImportUrl}
          actions={{
            selectFont: fonts.selectTabularFont,
            selectFamily: fonts.selectTabularFamily,
            setFontImportUrl: fonts.setTabularFontImportUrl,
          }}
        />
      </SlatSection>
      <SlatSection title="Font Weights" optional>
        <>
          <FontWeightSelect
            fontWeight="light"
            selectedFont={selectedFont}
            isCustomFont={fontSource === 'customfont'}
          />
          <FontWeightSelect
            fontWeight="regular"
            selectedFont={selectedFont}
            isCustomFont={fontSource === 'customfont'}
          />
          <FontWeightSelect
            fontWeight="semibold"
            selectedFont={selectedFont}
            isCustomFont={fontSource === 'customfont'}
          />
          <FontWeightSelect
            fontWeight="bold"
            selectedFont={selectedFont}
            isCustomFont={fontSource === 'customfont'}
          />
        </>
      </SlatSection>
    </Slat>
  );
};
export default React.memo(FontSlat);
