import { combineReducers } from 'redux';
import fonts from './fonts/reducers';
import app from './app/reducers';
import icons from './icons/reducers';
import colors from './colors/reducers';
import links from './links/reducers';
import brand from './brand/reducers';
import logos from './logos/reducers';
import originalBranding from './originalBranding/reducers';
import productTour from './productTour/reducers';
import tooltips from './tooltips/reducers';
import features from './features/reducers';

export default combineReducers({
  fonts,
  app,
  icons,
  colors,
  links,
  brand,
  logos,
  originalBranding,
  productTour,
  tooltips,
  features,
});
