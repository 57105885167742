const STRING = /./;
const URL = /(http)s?(:\/\/)./;
const EMAIL = /[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)*/;
const NUMBER = /\d/;

export const validationModal = {
  brand: {
    brandName: STRING,
    website: URL,
  },
  colors: {
    primary: STRING,
    secondary: STRING,
    topbarInverted: null,
    success: STRING,
    danger: STRING,
    warning: STRING,
    info: STRING,
  },
  fonts: {
    selectedFont: null,
    fontFamily: STRING,
    fontWeights: null,
    fontImportUrl: URL,
  },
  icons: {
      analytics: {
        color1: STRING,
        color2: null,
      },
      manager: {
        color1: STRING,
        color2: null,
      },
      communicator: {
        color1: STRING,
        color2: null,
      },
  },
  links: {
    supportEmail: EMAIL,
    supportPhone: NUMBER,
    termsUrl: URL,
    refundsUrl: URL,
    contactsUrl: URL,
    helpUrl: URL,
    twitter: null,
    twitterUrl: null,
  },
  logos: {
    company: {
      logoSrc: STRING,
      logoFormData: null,
    },
    product: {
      logoSrc: STRING,
      logoFormData: null,
    },
  },
};
