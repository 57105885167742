import React, { useState } from 'react';
import styles from './styles.module.scss';
import TopBar from '../TopBar';
import { NvButton } from 'nv-react-components-v2';
import DeviceEmulator from '../../DeviceEmulator/component';

const Analytics = (): JSX.Element => {
  const [showHamburger, setShowHamburger] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const sidebarItems = () => {
    const html = [];
    html.push(
      <div
        className={styles.analytics_sidebar_button}
        key={`analytics_sidebar_button`}
      >
        <NvButton size="small" fullWidth>
          NEW DASHBOARD
        </NvButton>
      </div>,
    );
    for (let i = 1; i < 9; i += 1) {
      html.push(
        <div
          className={styles.analytics_sidebar_item}
          key={`analytics_sidebar_item_${i}`}
        >
          Item {i}{' '}
        </div>,
      );
    }
    return html;
  };
  const dashboardItems = () => {
    const html = [];
    for (let i = 1; i < 8; i += 1) {
      html.push(
        <div
          className={styles.analytics_dashboard_item}
          key={`analytics_dashboard_item_${i}`}
        >
          <h4>Analytics Graph {i}</h4>{' '}
          <div className={styles.analytics_dashboard_item_graph} />
        </div>,
      );
    }
    return html;
  };
  return (
    <DeviceEmulator
      className={styles.device}
      onBreakpointChange={breakpointType => {
        setShowHamburger(breakpointType === 'xs' || breakpointType === 'sm');
      }}
    >
      <div className={styles.analytics_app}>
        <TopBar
          appName="analytics"
          showHamburger={showHamburger}
          onHamburgerClick={() => {
            setShowSidebar(!showSidebar);
          }}
        />
        <div
          className={[
            styles.analytics_sidebar,
            showSidebar ? styles.show : '',
          ].join(' ')}
        >
          {sidebarItems()}
        </div>
        <div className={styles.analytics_content}>
          <div className={styles.analytics_breadcrumb}>
            <span>This week (Apr 27 2020 - May 03 2020)</span>
            <span>Auto-refresh: off</span>
          </div>
          <div className={styles.analytics_dashboard_title}>
            <h1>Default Dashboard</h1>
          </div>
          <div className={styles.analytics_dashboard}>{dashboardItems()}</div>
        </div>
      </div>
    </DeviceEmulator>
  );
};

export default Analytics;
