import { brand } from '../actionTypes';

export const setBrandName = (brandName: string) => {
  return {
    type: brand.setBrandName,
    payload: {
      brandName,
    },
  };
};

export const setWebsite = (website: string) => {
  return {
    type: brand.setWebsite,
    payload: {
      website,
    },
  };
};

export const revertBrand = () => {
  return {
    type: brand.revertBrand,
  };
};

