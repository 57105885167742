import { LogoState, LogoAction } from './interfaces';
import { logos } from '../actionTypes';
import { Reducer } from 'redux';

const setProductLogo = (
  state: LogoState,
  payload: Partial<LogoAction['payload']>,
) => {
  return {
    ...state,
    modified: {
      ...state.modified,
      product: payload.product || state.modified.product,
    },
  };
};

const setCompanyLogo = (
  state: LogoState,
  payload: Partial<LogoAction['payload']>,
) => {
  return {
    ...state,
    modified: {
      ...state.modified,
      company: payload.company || state.modified.company,
    },
  };
};

const revertLogos = (state: LogoState) => {
  return {
    ...state,
    modified: { ...state.original },
  };
};

export const initialLogoState: LogoState = {
  original: {
    product: {
      logoSrc: '',
    },
    company: {
      logoSrc: '',
    },
  },
  modified: {
    product: { logoSrc: '' },
    company: {
      logoSrc: '',
    },
  },
};

const reducer: Reducer<LogoState, LogoAction> = (
  state: LogoState = initialLogoState,
  action: LogoAction,
) => {
  if (!action) {
    return { ...{}, ...state };
  }
  switch (action.type) {
    case logos.setProductLogo:
      return setProductLogo(state, action.payload);
    case logos.setCompanyLogo:
      return setCompanyLogo(state, action.payload);
    case logos.revertLogos:
      return revertLogos(state);
    default:
      return { ...state };
  }
};
export default reducer;
