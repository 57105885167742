import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCommentAlt,
  faMoon,
  faPaintBrush,
  faSignOutAlt,
  faStreetView,
  faSun,
} from '@fortawesome/free-solid-svg-icons';
import logo from '../../../logo.svg';
import { productTour } from '../../../store/actions';
import { appSelector } from '../../../store/selectors';
import { useDarkMode } from '../../../hooks/darkMode';
import styles from './styles.module.scss';
import Tooltip from '../../atoms/Tooltip';
import { TippyProps } from '@tippyjs/react';

const commonTooltipOptions: TippyProps = {
  arrow: false,
  placement: 'right',
};
const SideNav: React.FC = (): JSX.Element => {
  const route = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isDarkMode, toggleDarkMode] = useDarkMode();
  const { userName } = useSelector(appSelector);

  return (
    <nav className={styles.side_nav}>
      <div className={styles.top}>
        <img
          className={styles.logo}
          src={logo}
          alt="logo"
          title="Branding Interface"
        />
        <Tooltip content="Branding" {...commonTooltipOptions}>
          <FontAwesomeIcon
            className={`${styles.side_nav_button} ${
              route.path === '/app' ? styles.active : ''
            }`}
            icon={faPaintBrush}
            onClick={() => {
              history.push('/app');
            }}
          />
        </Tooltip>
        <Tooltip content="Tooltips" {...commonTooltipOptions}>
          <FontAwesomeIcon
            className={`${styles.side_nav_button} ${
              route.path === '/tooltip' ? styles.active : ''
            }`}
            icon={faCommentAlt}
            onClick={() => {
              history.push('/tooltip');
            }}
          />
        </Tooltip>
      </div>
      <div className={styles.bottom}>
        {route.path === '/app' && (
          <Tooltip content="Play Tour" {...commonTooltipOptions}>
            <FontAwesomeIcon
              className={styles.side_nav_button}
              onClick={() => {
                dispatch(productTour.setBrandingTourStep(0));
                dispatch(productTour.setBrandingTourRun(true));
              }}
              icon={faStreetView}
            />
          </Tooltip>
        )}
        <Tooltip
          content={`Toggle Dark Mode (Current: ${
            isDarkMode ? 'Dark' : 'Light'
          })`}
          {...commonTooltipOptions}
        >
          <FontAwesomeIcon
            className={styles.side_nav_button}
            onClick={toggleDarkMode}
            icon={isDarkMode ? faSun : faMoon}
          />
        </Tooltip>
        <Tooltip content={`Log out (${userName})`} {...commonTooltipOptions}>
          <FontAwesomeIcon
            className={styles.side_nav_button}
            onClick={() => {
              history.push('/logout');
            }}
            icon={faSignOutAlt}
          />
        </Tooltip>
      </div>
    </nav>
  );
};

export default SideNav;
