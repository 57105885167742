import { fonts } from '../actionTypes';
import { FontModel } from './interfaces';
import { Dispatch } from 'redux';
import { State } from '../interfaces';
import { getFontImportUrl } from '../../helpers/fonts';
import { BRANDING_API } from '../../helpers/constants';

// actions
export const fetchGoogleFonts = () => {
  return async (dispatch: Dispatch, getState: () => State) => {
    const { accessToken } = getState().app;
    const fetchFonts = await fetch(BRANDING_API.GOOGLE_FONTS, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const result = await fetchFonts.json();
    dispatch({
      type: fonts.fetchGoogleFonts,
      payload: {
        googleFonts: result.items,
      },
    });
  };
};
export const selectFont = (selectedFont: FontModel['selectedFont']) => {
  return {
    type: fonts.selectFont,
    payload: {
      selectedFont,
    },
  };
};

export const selectFamily = (fontFamily: FontModel['fontFamily']) => {
  return {
    type: fonts.selectFamily,
    payload: {
      fontFamily,
    },
  };
};
export const setFontImportUrl = (
  fontImportUrl?: FontModel['fontImportUrl'],
) => {
  if (fontImportUrl !== undefined) {
    return {
      type: fonts.setFontImportUrl,
      payload: {
        fontImportUrl,
      },
    };
  }
  return (dispatch: Dispatch, getState: () => State) => {
    const fontImportUrl = getFontImportUrl(
      getState().fonts.modified,
      'regular',
    );
    dispatch({
      type: fonts.setFontImportUrl,
      payload: {
        fontImportUrl,
      },
    });
  };
};

export const selectTabularFont = (
  selectedTabularFont: FontModel['selectedTabularFont'],
) => {
  return {
    type: fonts.selectTabularFont,
    payload: {
      selectedTabularFont,
    },
  };
};

export const selectTabularFamily = (
  tabularFontFamily: FontModel['tabularFontFamily'],
) => {
  return {
    type: fonts.selectTabularFamily,
    payload: {
      tabularFontFamily,
    },
  };
};
export const setTabularFontImportUrl = (
  tabularFontImportUrl?: FontModel['tabularFontImportUrl'],
) => {
  if (tabularFontImportUrl !== undefined) {
    return {
      type: fonts.setTabularFontImportUrl,
      payload: {
        tabularFontImportUrl,
      },
    };
  }
  return (dispatch: Dispatch, getState: () => State) => {
    const tabularFontImportUrl = getFontImportUrl(
      getState().fonts.modified,
      'tabular',
    );
    dispatch({
      type: fonts.setTabularFontImportUrl,
      payload: {
        tabularFontImportUrl,
      },
    });
  };
};

export const setFontWeightLight = (fontWeightLight: string) => {
  return {
    type: fonts.setFontWeightLight,
    payload: {
      fontWeightLight,
    },
  };
};
export const setFontWeightRegular = (fontWeightRegular: string) => {
  return {
    type: fonts.setFontWeightRegular,
    payload: {
      fontWeightRegular,
    },
  };
};
export const setFontWeightSemibold = (fontWeightSemibold: string) => {
  return {
    type: fonts.setFontWeightSemibold,
    payload: {
      fontWeightSemibold,
    },
  };
};
export const setFontWeightBold = (fontWeightBold: string) => {
  return {
    type: fonts.setFontWeightBold,
    payload: {
      fontWeightBold,
    },
  };
};
export const revertFonts = () => {
  return { type: fonts.revertFonts };
};
