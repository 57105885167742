import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { NvLogin } from 'nv-react-components-v2';
import styles from './styles.module.scss';
import { useDispatch } from 'react-redux';
import { app } from '../../../store/actions';
import { decode } from 'jsonwebtoken';
import logo from '../../../logoLight.svg';
import setTitle from '../../../helpers/setTitle';

const Login: React.FC = (): JSX.Element => {
  useEffect(() => {
    setTitle('Login');
  }, []);

  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();

  interface DecodedJWT {
    email: string;
    exp: number;
    family_name: string;
    given_name: string;
    iat: string;
    jti: string;
    preferred_username: string;
    rid: number;
    teams: string[];
    ual: string;
    uid: number;
  }
  const allowedAccessLevel = ['RESELLER', 'SUPPORT_L2', 'ADMIN'];
  return (
    <NvLogin
      logoSrc={logo}
      localStorageKey="branding-interface"
      onToken={(accessToken, expires) => {
        const decodedJwt = decode(accessToken, { json: true });
        const accessLevel = decodedJwt?.ual;
        const userName = decodedJwt?.preferred_username;
        const resellerId = decodedJwt?.rid;
        if (!allowedAccessLevel.includes(accessLevel)) {
          setErrorMessage(
            'This account does not have the required access level to use this app, please contact our support team for assistance.',
          );
          return;
        }

        // on successful login, dispatch action and route to /app
        dispatch(app.login());
        dispatch(app.setUserName(userName));
        dispatch(app.setUserAccessLevel(accessLevel));
        dispatch(app.setResellerIds(resellerId));
        dispatch(app.setAccessToken(accessToken));
        dispatch(app.setTokenExpires(expires))

        const rurl = new URL(window.location.href).searchParams.get('rurl');
        if (rurl) {
          history.push(rurl.replace(window.location.origin, ''));
        } else {
          history.push('/app');
        }
      }}
      className={styles.login_container}
      error={errorMessage}
      dev={false}
      inverted
      forgotPasswordLink={
        'https://partner.sipcentric.com/faces/forgotPassword.xhtml?rurl=' +
        window.location.href
      }
    />
  );
};
export default Login;
