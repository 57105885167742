import React from 'react';
import { NvLogin } from 'nv-react-components-v2';
import { useSelector } from 'react-redux';
import {
  modifiedColorsSelector,
  modifiedLogosSelector,
} from '../../../../store/selectors';
import DeviceEmulator from '../../DeviceEmulator/component';

const Login: React.FC = (): JSX.Element => {
  const {
    company: { logoSrc },
  } = useSelector(modifiedLogosSelector);
  const { topbarInverted } = useSelector(modifiedColorsSelector);

  return (
    <DeviceEmulator className={''}>
      <NvLogin onToken={() => {}} logoSrc={logoSrc} inverted={topbarInverted} />
    </DeviceEmulator>
  );
};
export default Login;
