import React from 'react';
import TopBar from '../TopBar';
import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faVoicemail,
  faSearch,
  faPhoneAlt,
} from '@fortawesome/free-solid-svg-icons';
import DeviceEmulator from '../../DeviceEmulator/component';

const Communicator = (): JSX.Element => {
  const sidebarItems = () => {
    const html = [];
    html.push(
      <div
        className={styles.comms_sidebar_heading}
        key={'comms_sidebar_heading'}
      >
        <p>Extension</p>
        <p>Phonebook</p>
        <p>All</p>
      </div>,
    );
    for (let i = 1; i < 9; i += 1) {
      html.push(
        <div
          className={styles.comms_sidebar_item}
          key={`comms_sidebar_item_${i}`}
        >
          <FontAwesomeIcon icon={faVoicemail} />
          Extension {i}{' '}
        </div>,
      );
    }
    return html;
  };
  const rightbarItems = () => {
    const html = [];
    html.push(
      <div
        className={styles.comms_sidebar_heading}
        key={'cooms_righbar_heading'}
      >
        <p style={{ color: 'grey' }}>02 May 2020</p>
      </div>,
    );
    for (let i = 1; i < 4; i += 1) {
      html.push(
        <div
          className={styles.right_sidebar_item}
          key={`comms_rightbar_item_${i}`}
        >
          {' '}
          <FontAwesomeIcon icon={faPhoneAlt} />
          Caller {i}{' '}
        </div>,
      );
    }
    return html;
  };
  return (
    <DeviceEmulator className={styles.device}>
      <div className={styles.comms_app}>
        <TopBar appName="communicator" />
        <div className={styles.wrapper}>
          <div className={styles.comms_sidebar}>{sidebarItems()}</div>
          <div className={styles.mid_content}>
            <span className={styles.search_phonebook}>
              <FontAwesomeIcon icon={faSearch} />
              123
            </span>
            <div className={styles.keypad}>
              <span className={styles.number}>
                <span>1</span>
                <p>_</p>
              </span>
              <span className={styles.number}>
                <span>2</span>
                <p>ABC</p>
              </span>
              <span className={styles.number}>
                <span>3</span>
                <p>DEF</p>
              </span>
              <span className={styles.number}>
                <span>4</span>
                <p>GHI</p>
              </span>
              <span className={styles.number}>
                <span>5</span>
                <p>JKL</p>
              </span>
              <span className={styles.number}>
                <span>6</span>
                <p>MNO</p>
              </span>
              <span className={styles.number}>
                <span>7</span>
                <p>PQRS</p>
              </span>
              <span className={styles.number}>
                <span>8</span>
                <p>TUV</p>
              </span>
              <span className={styles.number}>
                <span>9</span>
                <p>WXYZ</p>
              </span>
              <span className={styles.number}>*</span>
              <span className={styles.number}>
                <span>0</span>
                <p>+</p>
              </span>
              <span className={styles.number}>#</span>
            </div>
            <span className={styles.phonebutton}>
              <FontAwesomeIcon icon={faPhoneAlt} />
            </span>
            <span className={styles.ongoing_calls}>No ongoing calls</span>
          </div>
          <div className={styles.right_sidebar}>{rightbarItems()}</div>
        </div>
      </div>
    </DeviceEmulator>
  );
};

export default Communicator;
