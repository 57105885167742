import { faInfoCircle, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styles from './styles.module.scss';

const Calls = (): JSX.Element => {
  const exampleCalls = [
    { name: 'Fred Bloggs', number: '07918371957', time: '09:43' },
    { name: 'John Doe', number: '07935193713', time: '11:02' },
    { name: 'Natalie Baker', number: '07936592746', time: '16:29' },
  ];
  return (
    <>
      <header className={styles.header}>
        <h1>Calls</h1>
      </header>
      <section className={styles.main}>
        <div className={styles.calls_container}>
          <input
            type="text"
            className={styles.search}
            placeholder="Search Calls"
          />
          <ul className={styles.calls_list}>
            {exampleCalls.map(({ name, number, time }) => (
              <li className={styles.call_item} key={name + number + time}>
                <FontAwesomeIcon
                  icon={faPhoneAlt}
                  className={styles.outgoing}
                />
                <span className={styles.name}>{name}</span>
                <span className={styles.number}>{number}</span>
                <span className={styles.time}>{time}</span>
                <FontAwesomeIcon icon={faInfoCircle} className={styles.info} />
              </li>
            ))}
          </ul>
        </div>
      </section>
    </>
  );
};

export default Calls;
