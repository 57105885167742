import React, { useEffect } from 'react';
import Slat from '../../atoms/Slat';
import SlatInput from '../../atoms/SlatBlocks/SlatInput';
import { NvInput } from 'nv-react-components-v2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie } from '@fortawesome/free-solid-svg-icons';
import { Props } from './interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { brand, app } from '../../../store/actions';
import { validationModal } from '../../../helpers/validate';
import { modifiedBrandSelector, validitySelector } from '../../../store/selectors';

const BrandSlat: React.FC<Props> = (): JSX.Element => {
  const dispatch = useDispatch();
  const { brandName, website } = useSelector(modifiedBrandSelector);
  const { brand: isSlatValid } = useSelector(validitySelector);

  useEffect(() => {
    const valid =
      validationModal.brand.brandName.test(brandName) &&
      validationModal.brand.website.test(website);
    if (valid === isSlatValid) return;
    dispatch(app.updateValidity({ brand: valid }));
  }, [brandName, dispatch, isSlatValid, website]);

  return (
    <Slat
      title="Brand"
      icon={<FontAwesomeIcon icon={faUserTie} />}
      cancelOnClick={() => {
        dispatch(brand.revertBrand());
      }}
      isInvalid={!isSlatValid}
    >
      <SlatInput label="Brand Name">
        <NvInput
          required
          useBrowserValidation
          value={brandName}
          onChange={e => {
            dispatch(brand.setBrandName(e.target.value));
          }}
          color={{
            background: 'var(--baseLight)',
            text: 'var(--copy)',
          }}
          placeholder="My Brand"
          reserveErrorSpace
          useBrowserValidationMessage
        />
      </SlatInput>
      <SlatInput label="Website">
        <NvInput
          type="url"
          required
          useBrowserValidation
          value={website}
          onChange={e => {
            dispatch(brand.setWebsite(e.target.value));
          }}
          color={{
            background: 'var(--baseLight)',
            text: 'var(--copy)',
          }}
          placeholder="https://www.mywebsite.com"
          reserveErrorSpace
          useBrowserValidationMessage
        />
      </SlatInput>
    </Slat>
  );
};
export default React.memo(BrandSlat);
