import React from 'react';
import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faBackspace } from '@fortawesome/free-solid-svg-icons';

const DialPad = (): JSX.Element => {
  const [value, setValue] = React.useState('');
  const SYMBOLS_MAP = new Map([
    ['1', '-'],
    ['2', 'ABC'],
    ['3', 'DEF'],
    ['4', 'GHI'],
    ['5', 'JKL'],
    ['6', 'MNO'],
    ['7', 'PQRS'],
    ['8', 'TUV'],
    ['9', 'WXYZ'],
    ['*', ''],
    ['0', '+'],
    ['#', ''],
  ]);
  return (
    <>
      <header className={styles.header}>
        <button className={styles.user_pill}>123 User Name</button>
      </header>
      <section className={styles.main}>
        <h3 className={styles.number_input}>{value}</h3>
        <div className={styles.dialpad}>
          {Array.from(SYMBOLS_MAP).map(([key, symbols]) => (
            <span
              className={styles.number}
              key={key}
              onClick={() => setValue(old => old + key)}
            >
              <span>{key}</span>
              <p>{symbols}</p>
            </span>
          ))}
          <span></span>
          <span className={styles.phonebutton}>
            <FontAwesomeIcon icon={faPhoneAlt} />
          </span>
          <span
            className={styles.backspace}
            onClick={() => setValue(old => old.slice(0, -1))}
          >
            <FontAwesomeIcon icon={faBackspace} />
          </span>
        </div>
      </section>
    </>
  );
};

export default DialPad;
