import React, { useEffect } from 'react';
import Slat from '../../atoms/Slat';
import SlatInput from '../../atoms/SlatBlocks/SlatInput';
import SlatHeader from '../../atoms/SlatBlocks/SlatHeader';
import SlatSection from '../../atoms/SlatBlocks/SlatSection';
import { Props } from './interfaces';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';

import { NvInput } from 'nv-react-components-v2';
import { links, app } from '../../../store/actions';
import { validationModal } from '../../../helpers/validate';
import { modifiedLinksSelector, validitySelector } from '../../../store/selectors';

type LinkInputType = 'email' | 'url' | 'number' | 'text';

const LinkSlat: React.FC<Props> = (): JSX.Element => {
  const dispatch = useDispatch();

  const linkState = useSelector(modifiedLinksSelector);
  const { links: isSlatValid } = useSelector(validitySelector);

  useEffect(() => {
    const invalidFields = Object.entries(linkState).filter(link => {
      const key = link[0];
      const value = link[1];
      const validator = validationModal.links as {
        [key: string]: RegExp | null;
      };
      if (!validator[key]) return false;
      return !validator[key]?.test(value);
    });
    const valid = invalidFields.length === 0;
    if (valid === isSlatValid) return;
    dispatch(app.updateValidity({ links: valid }));
  }, [dispatch, isSlatValid, linkState]);

  const LinkFields = (fieldsToRender: string[]) => {
    const html = [];
    const linkConfig = [
      // * [ linkProperty, displayLabel, required, type, placeholder ]
      [
        'supportEmail',
        'Customer Support Email',
        true,
        'email',
        'support@email.com',
      ],
      ['supportPhone', 'Customer Support Phone', true, 'number', '-'],
      ['termsUrl', 'Terms URL', true, 'url', 'https://www.mywebsite.com/terms'],
      [
        'contactsUrl',
        'Contacts URL',
        true,
        'url',
        'https://www.mywebsite.com/contact',
      ],
      [
        'refundsUrl',
        'Refunds URL',
        true,
        'url',
        'https://www.mywebsite.com/refunds',
      ],
      ['helpUrl', 'Help URL', true, 'url', 'https://www.mywebsite.com/help'],
      ['twitter', 'Twitter Name', false, 'text', 'Leave blank if N/A'],
      ['twitterUrl', 'Twitter URL', false, 'url', 'Leave blank if N/A'],
    ];
    for (const link in linkState) {
      // lets get the config for this link
      const config = linkConfig.find(element => element[0] === link);
      if (fieldsToRender.includes(link) && config) {
        let title = config?.[1] as string;
        let required = config?.[2] as boolean;
        let type = config?.[3] as LinkInputType;
        let placeholder = config?.[4] as string;
        let value = linkState[link] as string;
        if (type === 'number') {
          // remove all whitespace so that NvInput recognises as a number
          value = value.replace(/ /g, '');
        }
        html.push(
          <SlatInput label={title} key={title}>
            <NvInput
              value={value}
              onChange={e => {
                dispatch(links.setLinks({ [link]: e.target.value }));
              }}
              color={{
                background: 'var(--baseLight)',
                text: 'var(--copy)',
              }}
              useBrowserValidation
              required={required}
              type={type}
              placeholder={placeholder}
              useBrowserValidationMessage
              reserveErrorSpace
            />
          </SlatInput>,
        );
      }
    }
    return html;
  };

  return (
    <Slat
      title="Links"
      icon={<FontAwesomeIcon icon={faLink} />}
      cancelOnClick={() => {
        dispatch(links.revertLinks());
      }}
      isInvalid={!isSlatValid}
    >
      <SlatHeader title="Required" />
      {LinkFields([
        'supportEmail',
        'supportPhone',
        'contactsUrl',
        'helpUrl',
        'refundsUrl',
        'termsUrl',
      ])}
      <SlatSection title="Optional" optional>
        {LinkFields(['twitter', 'twitterUrl'])}
      </SlatSection>
    </Slat>
  );
};

export default React.memo(LinkSlat);
