import { colors } from '../actionTypes';

export const setPrimaryColor = (primary: string) => {
  return {
    type: colors.setPrimaryColor,
    payload: { primary },
    meta: {
      throttle: true,
    }
  };
};

export const setSecondaryColor = (secondary: string) => {
  return {
    type: colors.setSecondaryColor,
    payload: { secondary },
    meta: {
      throttle: true,
    }
  };
};
export const setSuccessColor = (success: string) => {
  return {
    type: colors.setSuccessColor,
    payload: { success },
    meta: {
      throttle: true,
    }
  };
};
export const setDangerColor = (danger: string) => {
  return {
    type: colors.setDangerColor,
    payload: { danger },
    meta: {
      throttle: true,
    }
  };
};
export const setWarningColor = (warning: string) => {
  return {
    type: colors.setWarningColor,
    payload: { warning },
    meta: {
      throttle: true,
    }
  };
};
export const setInfoColor = (info: string) => {
  return {
    type: colors.setInfoColor,
    payload: { info },
    meta: {
      throttle: true,
    }
  };
};

export const setTopbarInverted = (topbarInverted: boolean) => {
  return {
    type: colors.setTopbarInverted,
    payload: { topbarInverted },
  }
}
export const revertColors = () => {
  return {
    type: colors.revertColors,
  };
};
