import React from 'react';
import { Props } from './interfaces';
import { NvSelectDropdown } from 'nv-react-components-v2';
import SlatInput from '../../../atoms/SlatBlocks/SlatInput';

import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../../store/interfaces';
import { fonts } from '../../../../store/actions';

const prettyFontWeight = {
  light: 'Light',
  regular: 'Regular',
  semibold: 'Semibold',
  bold: 'Bold',
};
const FontWeightSelect: React.FC<Props> = ({
  fontWeight,
  selectedFont,
  isCustomFont,
}): JSX.Element => {
  const dispatch = useDispatch();
  const selector = useSelector;

  const fontWeightValue = useSelector(
    (state: State) => state.fonts.modified.fontWeights[fontWeight],
  );

  // select dropdown options
  const dropDownOptions = (() => {
    const googleFonts = selector(
      (state: State) => state.fonts.googleFonts,
      () => true,
    );
    const fontFamily = selector(
      (state: State) => state.fonts.modified.fontFamily,
      () => true,
    );
    const allWeights = [
      '100',
      '200',
      '300',
      '400',
      '500',
      '600',
      '700',
      '800',
      '900',
    ].map(weight => ({ label: weight, value: weight }));

    // * if user is using custom font, allow them to select any font weight
    if (isCustomFont) return allWeights;

    let someWeights: string[] = [];

    // user has searched googleFonts and obtained list of fontweights (variants)
    if (selectedFont) {
      someWeights = [...selectedFont.variants];
    } else {
      // else we reobtain the fontweights/ variants from googleFonts
      // case: user has just logged in and only wants to change fontweights
      const search = googleFonts.filter(
        font => font.family.toLowerCase() === fontFamily[0].toLowerCase(),
      );
      someWeights = search.map(item => item.variants)[0];
    }
    // the searched font does not exist
    if (!someWeights) return allWeights;

    return someWeights.reduce(
      (result: { label: string; value: string }[], variant) => {
        const processedValue = variant === 'regular' ? '400' : variant;
        // removing italic options
        if (!variant.includes('italic')) {
          result.push({ label: processedValue, value: processedValue });
        }
        return result;
      },
      [],
    );
  })();

  return (
    <SlatInput label={`Font Weight ${prettyFontWeight[fontWeight]}`}>
      <NvSelectDropdown
        options={dropDownOptions}
        fullWidth
        isSearchable={false}
        isClearable={false}
        color={{
          background: 'var(--baseLight)',
          text: 'var(--copy)',
          hover: 'var(--baseDark)',
          active: 'var(--accent)',
        }}
        onChange={value => {
          const selectedFontweight = value[0]?.value;
          switch (fontWeight) {
            case 'light':
              dispatch(fonts.setFontWeightLight(selectedFontweight));
              break;
            case 'regular':
              dispatch(fonts.setFontWeightRegular(selectedFontweight));
              break;
            case 'semibold':
              dispatch(fonts.setFontWeightSemibold(selectedFontweight));
              break;
            case 'bold':
              dispatch(fonts.setFontWeightBold(selectedFontweight));
              break;
          }
          if (!isCustomFont) dispatch(fonts.setFontImportUrl());
        }}
        value={
          dropDownOptions.find(option => option.value === fontWeightValue) ||
          fontWeightValue
        }
      />
    </SlatInput>
  );
};

export default React.memo(FontWeightSelect);
