import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { editor } from '@ckeditor/ckeditor5-core';
import IconPicker from 'react-icon-picker';
import { fas, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  NvButton,
  NvInput,
  NvModal,
  NvSelectDropdown,
} from 'nv-react-components-v2';

import Tabs from '../../atoms/Tabs';
import SlatHeader from '../../atoms/SlatBlocks/SlatHeader';

import { tooltips } from '../../../store/actions';
import { tooltipFrame } from '../../../helpers/tooltipFrame';
import { APITooltipElement } from '../../../types/Tooltips';
import { Props } from './interfaces';
import styles from './styles.module.scss';
import './ckeditor.scss';

interface Editor extends editor.Editor, editor.utils.DataApi {}

const tabs: APITooltipElement['type'][] = [
  'tooltip',
  'popup',
  'alert',
  'video',
];

const icons = Object.values(fas).map(icon => ({
  name: icon.iconName,
  render: () => <FontAwesomeIcon icon={icon} />,
}));

const alertKindOptions = ['success', 'danger', 'warning', 'info'].map(
  value => ({
    label: value[0].toUpperCase() + value.slice(1),
    value,
    prefix: <span>Color: </span>,
  }),
);

const tooltipPlacementOptions = ['auto', 'top', 'bottom', 'left', 'right'].map(
  value => ({
    label: value[0].toUpperCase() + value.slice(1),
    value,
    prefix: <span>Placement: </span>,
  }),
);

const iconColorOptions = [
  { label: 'Default', value: (undefined as any) as string },
  { label: 'Primary', value: 'var(--primary)' },
  { label: 'Secondary', value: 'var(--secondary)' },
  { label: 'Danger', value: 'var(--danger)' },
  { label: 'Success', value: 'var(--success)' },
  { label: 'Warning', value: 'var(--warning)' },
  { label: 'Info', value: 'var(--info)' },
];

const TooltipEditModal: React.FC<Props> = ({
  tooltipNode,
  onClose,
  apiData,
}) => {
  const dispatch = useDispatch();
  const editorRef = useRef<Editor | null>(null);
  const [tooltipApiData, setTooltipApiData] = useState(apiData);

  const onSave = () => {
    dispatch(tooltips.updateTooltips({ [tooltipNode.id]: tooltipApiData }));
    // send updated tooltip to iframe for instant preview of changes
    tooltipFrame.postMessage('update', { [tooltipNode.id]: tooltipApiData });
  };

  const mediaUrlInput = (
    <>
      <SlatHeader title="Media URL" />
      <NvInput
        value={tooltipApiData.videoEmbedUrl || ''}
        autoFocus
        onChange={e => {
          const videoEmbedUrl = e.target?.value || '';
          setTooltipApiData(prev => ({
            ...prev,
            videoEmbedUrl,
          }));
        }}
        color={{
          background: 'var(--baseLight)',
          text: 'var(--copy)',
        }}
      />
    </>
  );

  const ckEditorItemsToHide = ['uploadImage'];
  if (tooltipApiData.type === 'tooltip') {
    ckEditorItemsToHide.push(...['link', 'mediaEmbed', 'blockQuote', 'table']);
  }
  if (tooltipApiData.type === 'alert') {
    ckEditorItemsToHide.push(...['mediaEmbed', 'blockQuote']);
  }
  const htmlEditor = (
    <>
      <SlatHeader title="Content" />
      <CKEditor
        key={tooltipApiData.type}
        editor={ClassicEditor}
        data={tooltipApiData.html || ''}
        config={{
          mediaEmbed: {
            previewsInData: true,
            removeProviders: [
              'instagram',
              'twitter',
              'googleMaps',
              'flickr',
              'facebook',
            ],
          },
          toolbar: { removeItems: ckEditorItemsToHide },
        }}
        onReady={(editor: Editor) => {
          // You can store the "editor" and use when it is needed.
          editorRef.current = editor;
        }}
        onChange={() => {
          setTooltipApiData(prev => ({
            ...prev,
            html: editorRef.current?.getData(),
          }));
        }}
      />
    </>
  );

  const iconEditor = (
    <>
      <SlatHeader title="Icon" />
      <div className={styles.icon_editor_wrapper}>
        <fieldset className={styles.icon_fieldset}>
          <legend>Pick icon</legend>
          <IconPicker
            // ! HACK icon picker is uncontrolled, force rerender when setting icon to undefined
            key={
              tooltipApiData.icon === undefined
                ? 'icon-picker-no-icon'
                : 'icon-picker-has-icon'
            }
            icons={icons}
            defaultIcon={
              icons.find(icon => icon.name === tooltipApiData.icon) || {
                name: '',
                render: () => null,
              }
            }
            onIconSelected={(icon: { name: string }) => {
              setTooltipApiData(prev => ({ ...prev, icon: icon.name }));
            }}
            rowLength={10}
            visibleRows={5}
          />
          <NvButton
            onClick={() => {
              setTooltipApiData(prev => ({ ...prev, icon: undefined }));
            }}
            mini
            leftIcon={<FontAwesomeIcon icon={faTimes} />}
            borderless
            inverted
            kind="danger"
            disabled={!tooltipApiData.icon}
          />
        </fieldset>
        {tooltipApiData.icon && tooltipApiData.type !== 'alert' && (
          <>
            <fieldset className={styles.icon_fieldset}>
              <legend>Alignment</legend>
              <NvButton
                borderless={tooltipApiData.iconAlign === 'right'}
                inverted
                onClick={() => {
                  setTooltipApiData(prev => ({ ...prev, iconAlign: 'left' }));
                }}
              >
                Align Icon Left
              </NvButton>
              <NvButton
                borderless={tooltipApiData.iconAlign !== 'right'}
                inverted
                onClick={() => {
                  setTooltipApiData(prev => ({ ...prev, iconAlign: 'right' }));
                }}
              >
                Align Icon Right
              </NvButton>
            </fieldset>
            <fieldset className={styles.icon_fieldset}>
              <legend>Color</legend>
              <NvSelectDropdown
                options={iconColorOptions}
                value={iconColorOptions.find(
                  opt => opt.value === tooltipApiData.iconColor,
                )}
                onChange={opt => {
                  if (!opt?.[0]) return;
                  setTooltipApiData(prev => ({
                    ...prev,
                    iconColor: opt[0].value as any,
                  }));
                }}
                color={{
                  background: 'var(--baseLight)',
                  text: 'var(--copy)',
                  hover: 'var(--baseDark)',
                  active: 'var(--accent)',
                }}
                defaultValue={iconColorOptions.find(
                  opt => opt.value === undefined,
                )}
                isClearable={false}
                fullWidth
              />
            </fieldset>
          </>
        )}
      </div>
    </>
  );

  const alertSettingsEditor = (
    <>
      <SlatHeader title="Alert Settings" />
      <NvSelectDropdown
        options={alertKindOptions}
        value={alertKindOptions.find(
          opt => opt.value === tooltipApiData.alertKind,
        )}
        onChange={opt => {
          if (!opt?.[0]) return;
          setTooltipApiData(prev => ({
            ...prev,
            alertKind: opt[0].value as any,
          }));
        }}
        color={{
          background: 'var(--baseLight)',
          text: 'var(--copy)',
          hover: 'var(--baseDark)',
          active: 'var(--accent)',
        }}
        defaultValue={alertKindOptions.find(opt => opt.value === 'warning')}
        isClearable={false}
        isPrefixDisplayedInValue
      />
    </>
  );

  const popupSettingsEditor = (
    <>
      <SlatHeader title="Popup Settings" />
      <NvInput
        value={tooltipApiData.popupTitle || tooltipNode.label}
        onChange={e => {
          const popupTitle = e.target?.value || '';
          setTooltipApiData(prev => ({
            ...prev,
            popupTitle,
          }));
        }}
        color={{
          background: 'var(--baseLight)',
          text: 'var(--copy)',
        }}
        labelText="Popup Title"
        labelPosition="top"
      />
    </>
  );

  const tooltipSettingsEditor = (
    <>
      <SlatHeader title="Tooltip Settings" />
      <NvSelectDropdown
        options={tooltipPlacementOptions}
        value={tooltipPlacementOptions.find(
          opt => opt.value === tooltipApiData.tooltipPlacement,
        )}
        onChange={opt => {
          if (!opt?.[0]) return;
          setTooltipApiData(prev => ({
            ...prev,
            tooltipPlacement: opt[0].value as any,
          }));
        }}
        color={{
          background: 'var(--baseLight)',
          text: 'var(--copy)',
          hover: 'var(--baseDark)',
          active: 'var(--accent)',
        }}
        defaultValue={tooltipPlacementOptions.find(opt => opt.value === 'auto')}
        isClearable={false}
        isPrefixDisplayedInValue
      />
    </>
  );

  return (
    <NvModal
      show
      modalRoot={document.getElementById('modal_root')}
      className={styles.edit_modal}
      title={'Edit ' + tooltipNode.label}
      size="large"
      type="confirm"
      onClose={save => {
        if (save) {
          onSave();
        }
        onClose();
      }}
      confirmText="Save"
      cancelText="Cancel"
    >
      <div className={styles.tooltip_edit_modal_content}>
        {tooltipNode.hide !== undefined && (
          <strong className={styles.hidden_warning}>This {tooltipApiData.type} may not be visible to the end-user.</strong>
        )}
        <Tabs
          tabs={tabs}
          selectedTab={tooltipApiData.type || 'tooltip'}
          onTabChange={selectedTab => {
            setTooltipApiData(prev => ({
              ...prev,
              type: selectedTab as APITooltipElement['type'],
            }));
          }}
          dense
        />
        {tooltipApiData.type === 'video' ? mediaUrlInput : htmlEditor}
        {iconEditor}
        {tooltipApiData.type === 'alert' && alertSettingsEditor}
        {['popup', 'video'].includes(tooltipApiData.type) &&
          popupSettingsEditor}
        {['popup', 'video', 'tooltip'].includes(tooltipApiData.type) &&
          tooltipSettingsEditor}
      </div>
    </NvModal>
  );
};

export default TooltipEditModal;
