export const THEMES = {
  DARK: {
    baseDark: '#333',
    baseLight: '#454240',
    // baseDark: '#1a1a1a',
    // baseLight: '#292521',
    accent: '#f08017',
    copy: '#eee',
    danger: '#ff818d',
  },
  LIGHT: {
    baseDark: '#f5f5f5',
    baseLight: '#fff',
    accent: '#f08017',
    copy: '#13100D',
    danger: '#dc3445',
  },
};

export const BRANDING_API = {
  API_BASE_PROD: 'https://api.branding.partner.sipcentric.com',
  API_BASE_STAGING: 'https://staging.api.branding.partner.sipcentric.com',
  API_BASE_DEV: 'http://localhost:3030',
  get BRANDING() {
    return `${this.API_BASE_PROD}/branding`;
  },
  get TOOLTIP() {
    return `${this.API_BASE_PROD}/tooltip`;
  },
  get GOOGLE_FONTS() {
    return `${this.API_BASE_PROD}/google-fonts`;
  },
  get UPLOAD() {
    return `${this.API_BASE_PROD}/upload`;
  },
};
