import { ColorState, ColorAction } from './interfaces';
import { colors } from '../actionTypes';
import { Reducer } from 'redux';

const setPrimaryColor = (
  state: ColorState,
  payload: ColorAction['payload'],
) => {
  return {
    ...state,
    modified: {
      ...state.modified,
      primary: payload.primary || state.modified.primary,
    },
  };
};
const setSecondaryColor = (
  state: ColorState,
  payload: ColorAction['payload'],
) => {
  return {
    ...state,
    modified: {
      ...state.modified,
      secondary: payload.secondary || state.modified.secondary,
    },
  };
};
const setTopbarInverted = (
  state: ColorState,
  payload: ColorAction['payload'],
) => {
  return {
    ...state,
    modified: {
      ...state.modified,
      topbarInverted: payload.topbarInverted ?? state.modified.topbarInverted,
    },
  };
};

const setSuccessColor = (
  state: ColorState,
  payload: ColorAction['payload'],
) => {
  return {
    ...state,
    modified: {
      ...state.modified,
      success: payload.success || state.modified.success,
    },
  };
};
const setDangerColor = (state: ColorState, payload: ColorAction['payload']) => {
  return {
    ...state,
    modified: {
      ...state.modified,
      danger: payload.danger || state.modified.danger,
    },
  };
};
const setWarningColor = (
  state: ColorState,
  payload: ColorAction['payload'],
) => {
  return {
    ...state,
    modified: {
      ...state.modified,
      warning: payload.warning || state.modified.warning,
    },
  };
};
const setInfoColor = (state: ColorState, payload: ColorAction['payload']) => {
  return {
    ...state,
    modified: {
      ...state.modified,
      info: payload.info || state.modified.info,
    },
  };
};

const revertColors = (state: ColorState) => {
  return {
    ...state,
    modified: { ...state.original },
  };
};
export const initialColorState: ColorState = {
  original: {
    primary: '',
    secondary: '',
    topbarInverted: false,
    success: '#28a745',
    danger: '#dc3545',
    warning: '#ffc107',
    info: '#17a2b8',
  },
  modified: {
    primary: '',
    secondary: '',
    topbarInverted: false,
    success: '#28a745',
    danger: '#dc3545',
    warning: '#ffc107',
    info: '#17a2b8',
  },
};

const reducer: Reducer<ColorState, ColorAction> = (
  state: ColorState = initialColorState,
  action: ColorAction,
) => {
  if (!action) {
    return { ...{}, ...state };
  }
  switch (action.type) {
    case colors.setPrimaryColor:
      return setPrimaryColor(state, action.payload);
    case colors.setSecondaryColor:
      return setSecondaryColor(state, action.payload);
    case colors.setTopbarInverted:
      return setTopbarInverted(state, action.payload);
    case colors.setSuccessColor:
      return setSuccessColor(state, action.payload);
    case colors.setDangerColor:
      return setDangerColor(state, action.payload);
    case colors.setWarningColor:
      return setWarningColor(state, action.payload);
    case colors.setInfoColor:
      return setInfoColor(state, action.payload);
    case colors.revertColors:
      return revertColors(state);
    default:
      return { ...state };
  }
};
export default reducer;
