import React, { useEffect } from 'react';
import Slat from '../../atoms/Slat';
import { NvCheckbox } from 'nv-react-components-v2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListAlt } from '@fortawesome/free-solid-svg-icons';
import { Props } from './interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { features, app } from '../../../store/actions';
import {
  modifiedFeaturesSelector,
  validitySelector,
} from '../../../store/selectors';
import SlatHeader from '../../atoms/SlatBlocks/SlatHeader';

const FeatureSlat: React.FC<Props> = (): JSX.Element => {
  const dispatch = useDispatch();
  const { provisioningEnabled } = useSelector(modifiedFeaturesSelector);
  const { features: isSlatValid } = useSelector(validitySelector);

  useEffect(() => {
    const valid = true;
    if (valid === isSlatValid) return;
    dispatch(app.updateValidity({ features: valid }));
  }, [dispatch, isSlatValid]);

  return (
    <Slat
      title="Features"
      icon={<FontAwesomeIcon icon={faListAlt} />}
      cancelOnClick={() => {
        dispatch(features.revertFeatures());
      }}
      isInvalid={false}
    >
      <SlatHeader title={'Provisioning'}>
        <NvCheckbox
          kind="toggle"
          size="small"
          checked={provisioningEnabled}
          onChange={() => {
            dispatch(features.setProvisioningEnabled(!provisioningEnabled));
          }}
        />
      </SlatHeader>
    </Slat>
  );
};
export default React.memo(FeatureSlat);
