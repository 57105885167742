import React, { useEffect, useLayoutEffect, useState } from 'react';
import useDimensions from 'react-use-dimensions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BreakpointType, Props } from './interfaces';
import styles from './styles.module.scss';
import {
  faDesktop,
  faExpand,
  faLaptop,
  faMobile,
  faTablet,
  faWindowMaximize,
  faWindowMinimize,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../../atoms/Tooltip';

const breakpoints: {
  [key in BreakpointType]: {
    value: number;
    icon: IconDefinition;
    display: string;
  };
} = {
  xs: { value: 320, icon: faMobile, display: 'Mobile' },
  sm: { value: 576, icon: faTablet, display: 'Tablet' },
  md: { value: 768, icon: faLaptop, display: 'Laptop' },
  lg: { value: 992, icon: faDesktop, display: 'Desktop' },
};
const widthToBreakpoint = (width: number) => {
  let breakpointType: BreakpointType = 'lg';
  Object.entries(breakpoints).some(([type, { value }]) => {
    if (width <= value) {
      breakpointType = type as BreakpointType;
      return true;
    }
    return false;
  });
  return breakpointType;
};

const isMobile = /Mobi/i.test(window.navigator.userAgent);

/**
 * Wrapper for the app mockups
 *
 * Sets data-breakpoint to 'xs', 'sm', 'md', or 'lg' depending on the container width
 *
 * Apply appropriate responsive styles for .{className}[data-breakpoint={breakpointType}]
 */
const DeviceEmulator: React.FC<Props> = ({
  children,
  className,
  onBreakpointChange = () => {},
}) => {
  const [ref, { width }] = useDimensions();
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const [breakpointType, setBreakpointType] = useState<BreakpointType>(
    widthToBreakpoint(width),
  );
  const [fullscreen, setFullscreen] = useState(false);

  useLayoutEffect(() => {
    setBreakpointType(widthToBreakpoint(width));
  }, [width]);

  useEffect(() => {
    onBreakpointChange(breakpointType);
  }, [breakpointType, onBreakpointChange]);

  const setWidth = (width: number | 'fill', callback: () => void) => {
    if (containerRef) {
      const styleWidth = width === 'fill' ? '100%' : `${width}px`;
      containerRef.style.width = styleWidth;
      callback();
    }
  };

  return (
    <div
      className={[
        styles.device_emulator_wrapper,
        fullscreen ? styles.fullscreen : '',
      ].join(' ')}
    >
      <div
        ref={el => {
          ref(el);
          setContainerRef(el);
        }}
        className={[styles.device_emulator, className].join(' ')}
        data-breakpoint={breakpointType}
      >
        {children}
      </div>
      {!isMobile && (
        <menu>
          <span>View: </span>
          {Object.entries(breakpoints).map(
            ([type, { value, icon, display }]) => (
              <Tooltip content={display} key={type}>
                <span>
                  <FontAwesomeIcon
                    onClick={() => {
                      setWidth(value, () => {
                        setBreakpointType(type as BreakpointType);
                      });
                    }}
                    icon={icon}
                    style={{ cursor: 'pointer' }}
                  />
                </span>
              </Tooltip>
            ),
          )}
          <Tooltip content="Fill">
            <span>
              <FontAwesomeIcon
                onClick={() => {
                  setWidth('fill', () => {
                    setBreakpointType(widthToBreakpoint(width));
                  });
                }}
                icon={faExpand}
                style={{ cursor: 'pointer' }}
              />
            </span>
          </Tooltip>
          <Tooltip content={fullscreen ? 'Exit Fullscreen' : 'Fullscreen'}>
            <span>
              <FontAwesomeIcon
                onClick={() => {
                  setWidth('fill', () => {
                    setBreakpointType(widthToBreakpoint(width));
                    setFullscreen(!fullscreen);
                  });
                }}
                icon={fullscreen ? faWindowMinimize : faWindowMaximize}
                style={{ cursor: 'pointer' }}
              />
            </span>
          </Tooltip>
        </menu>
      )}
    </div>
  );
};

export default DeviceEmulator;
