import { logos } from '../actionTypes';
import { LogoModel } from './interfaces';

export const setProductLogo = (product: LogoModel['product']) => {
  return {
    type: logos.setProductLogo,
    payload: {
      product
    },
  };
};

export const setCompanyLogo = (company: LogoModel['company']) => {
  return {
    type: logos.setCompanyLogo,
    payload: {
      company
    },
  };
};


export const revertLogos = () => {
  return {
    type: logos.revertLogos,
  };
};



