import { createStore, applyMiddleware } from 'redux';
import rootReducer from './rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import throttle from 'redux-throttle';
import thunk from 'redux-thunk';

export const configureStore = () => {
  const middleware = [throttle(1000, { trailing: true }), thunk];
  const enhancers = composeWithDevTools(applyMiddleware(...middleware));

  const store = createStore(rootReducer, enhancers);
  return store;
};
