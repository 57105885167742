/** Functions for interacting with the storage api */

import { StorageItem, StoragePostBody } from '../types/Storage';

const appId = 'SacLPJcJ7lwfgBwNdd2QgRW0'; // unique id for use with storage api
const baseUrl = 'https://pbx.sipcentric.com/storage_api/v1/userdata';

const headers = {
  'x-nimvelo-app-id': appId,
  'Content-Type': 'application/json',
  'X-WWW-Authenticate': 'false',
};
const get = async (accessToken: string, type: string) => {
  let data: StorageItem[];
  try {
    const response = await fetch(`${baseUrl}/?type=${type}`, {
      headers: { ...headers, Authorization: `Bearer ${accessToken}` },
    });
    data = await response.json();
    return data;
  } catch (e) {
    console.error(e);
  }
};

const set = async (
  accessToken: string,
  body: StoragePostBody,
  docId?: string,
) => {
  try {
    if (docId) {
      // update
      const response = await fetch(`${baseUrl}/${docId}`, {
        method: 'PUT',
        headers: { ...headers, Authorization: `Bearer ${accessToken}` },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      return data as StorageItem;
    } else {
      // create
      const response = await fetch(`${baseUrl}`, {
        method: 'POST',
        headers: { ...headers, Authorization: `Bearer ${accessToken}` },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      return data as StorageItem;
    }
  } catch (e) {
    console.log(e);
  }
};

export default {
  get,
  set,
};
