import React, { useEffect } from 'react';
import Slat from '../../atoms/Slat';
import SlatInput from '../../atoms/SlatBlocks/SlatInput';
import { NvFileInput } from 'nv-react-components-v2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-solid-svg-icons';
import { Props } from './interfaces';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { logos as logoActions, app } from '../../../store/actions';
import { LogoAction, LogoModel } from '../../../store/logos/interfaces';
import { modifiedLogosSelector, validitySelector } from '../../../store/selectors';

const LogoSlat: React.FC<Props> = (): JSX.Element => {
  const dispatch = useDispatch();
  const logos = useSelector(modifiedLogosSelector);
  const { logos: isSlatValid } = useSelector(validitySelector);

  useEffect(() => {
    const valid = !!logos.company.logoSrc;
    if (valid === isSlatValid) return;
    dispatch(app.updateValidity({ logos: valid }));
  }, [dispatch, isSlatValid, logos]);

  type LogoTypes = 'company' | 'product';
  const logoTypes: LogoTypes[] = ['company', 'product'];
  const logoPrettyNames: { [key in LogoTypes]: string } = {
    company: 'Company',
    product: 'Product',
  };

  const handleFileUpload = (logoType: LogoTypes) => (files: File[]) => {
    const actionMap: {
      [key in LogoTypes]: (logo: LogoModel[LogoTypes]) => LogoAction;
    } = {
      company: logoActions.setCompanyLogo,
      product: logoActions.setProductLogo,
    };
    const action = actionMap[logoType];
    // get file
    const file = files?.[0];
    // if file doesnt exist stop here
    if (!file)
      return dispatch(
        action({
          logoSrc: '',
          logoFormData: undefined,
        }),
      );
    // create image blob
    const logoBlob = URL.createObjectURL(file);
    // create form data
    const logoFormData = new FormData();
    logoFormData.append('logo', file);
    dispatch(action({ logoSrc: logoBlob, logoFormData }));
  };

  return (
    <Slat
      title="Logos"
      icon={<FontAwesomeIcon icon={faImages} />}
      cancelOnClick={() => {
        dispatch(logoActions.revertLogos());
      }}
      isInvalid={!isSlatValid}
    >
      {logoTypes.map(logoType => (
        <SlatInput
          label={`${logoPrettyNames[logoType]} Logo`}
          required={!isSlatValid && logoType === 'company'}
          key={`${logoType}-file-upload`}
          tooltip={
            logoType === 'company'
              ? 'Visible on login and topbar'
              : 'Will be shown on topbar instead of company logo if provided'
          }
        >
          <NvFileInput
            inputText="Drag &#38; drop image or click to upload"
            acceptedMimeTypes={['image/png', 'image/jpeg', 'image/svg+xml']}
            onFileChange={handleFileUpload(logoType)}
            formatErrorMessage="Logo should be type .png .jpg .svg"
            icon="left"
            kind="area"
            disableWindowDragEvents
            fullWidth
            disabled={logoType !== 'company' && !logos.company.logoSrc}
          />
          <div className={styles.logo_container}>
            {logos.company.logoSrc && (
              <img
                src={logos[logoType].logoSrc || logos.company.logoSrc}
                alt="logo"
              ></img>
            )}
          </div>
        </SlatInput>
      ))}
    </Slat>
  );
};
export default React.memo(LogoSlat);
