import { features } from '../actionTypes';

export const setProvisioningEnabled = (enabled: boolean) => {
  return {
    type: features.setProvisioningEnabled,
    payload: {
      provisioningEnabled: enabled,
    },
  };
};

export const revertFeatures = () => {
  return {
    type: features.revertFeatures,
  };
};
