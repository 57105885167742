import { Dispatch } from 'redux';
import storage from '../../helpers/storage';
import { ProductTourResponse } from '../../types/ProductTour';
import { StoragePostBody } from '../../types/Storage';
import { productTour } from '../actionTypes';
import { State } from '../interfaces';
import { ProductTourState, TourStatus } from './interfaces';

const STORAGETYPE = 'product-tour-status';
export const fetchProductTour = () => {
  return async (dispatch: Dispatch, getState: () => State) => {
    const { accessToken } = getState().app;

    let storageDocId = '';
    let data: ProductTourResponse | undefined = undefined;
    try {
      const res = await storage.get(accessToken, STORAGETYPE);
      const tourStatusObj = res?.[0];
      if (tourStatusObj) {
        storageDocId = tourStatusObj._id;
        data = JSON.parse(tourStatusObj.data) as ProductTourResponse;
      }
    } finally {
      dispatch({
        type: productTour.fetchProductTour,
        payload: {
          storageDocId,
          branding: {
            run: false,
            stepIndex: data?.branding?.step || 0,
            complete: data?.branding?.complete ?? true,
          },
        },
      });
      return data;
    }
  };
};

const setRun = (type: keyof Omit<ProductTourState, 'storageDocId'>) => (
  run: boolean,
) => {
  return {
    type: productTour[type].setRun,
    payload: {
      [type]: {
        run,
        stepIndex: undefined,
      },
    },
  };
};

export const setBrandingTourRun = setRun('branding');

const setStep = (type: keyof Omit<ProductTourState, 'storageDocId'>) => (
  step: number,
) => {
  return {
    type: productTour[type].setStep,
    payload: {
      [type]: {
        run: undefined,
        stepIndex: step,
      },
    },
  };
};

export const setBrandingTourStep = setStep('branding');

const saveTour = (type: keyof Omit<ProductTourState, 'storageDocId'>) => (
  tourStatus: TourStatus,
) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    const { accessToken } = getState().app;
    const storageDocId = getState().productTour.storageDocId;
    const body: StoragePostBody = {
      type: STORAGETYPE,
      data: JSON.stringify({
        [type]: {
          complete: tourStatus.complete,
          step: tourStatus.stepIndex,
        },
      }),
    };
    try {
      const data = await storage.set(accessToken, body, storageDocId);
      dispatch({
        type: productTour.branding.saveTour,
        payload: {
          [type]: tourStatus,
          storageDocId: data?._id,
        },
      });
    } catch (err) {
      throw err;
    }
  };
};

export const saveBrandingTour = saveTour('branding');
