import { IconState, IconAction } from './interfaces';
import { icons } from '../actionTypes';
import { Reducer } from 'redux';

const setColors = (state: IconState, payload: IconAction['payload']) => {
  let modified: IconState['modified'] = {};
  for (const appName in state.original) {
    modified[appName] = {
      ...(state.modified[appName] || {}),
      ...payload[appName],
    };
  }
  return {
    ...state,
    modified,
  };
};
const revertIcons = (state: IconState) => {
  return {
    ...state,
    modified: { ...state.original },
  };
};

export const initialIconState: IconState = {
  original: {
    manager: { color1: '', color2: '' },
    analytics: { color1: '', color2: '' },
    communicator: { color1: '', color2: '' },
  },
  modified: {
    manager: { color1: '', color2: '' },
    analytics: { color1: '', color2: '' },
    communicator: { color1: '', color2: '' },
  },
};

const reducer: Reducer<IconState, IconAction> = (
  state: IconState = initialIconState,
  action: IconAction,
) => {
  if (!action) {
    return { ...{}, ...state };
  }
  switch (action.type) {
    case icons.setColors:
      return setColors(state, action.payload);
    case icons.revertIcons:
      return revertIcons(state);
    default:
      return { ...state };
  }
};
export default reducer;
