import { IconState } from '../store/icons/interfaces';

const iconSrcGenerator = (icons: IconState['modified']) => {
  let iconSrc: { [appName: string]: string } = {};
  const iconSrcBase = 'https://my.nimvelo.com';
  const appArr = Object.keys(icons).sort();
  //iterate through icons object
  appArr.forEach(app => {
    const appObj = icons[app];
    // remove # from color
    const color1 = appObj.color1.replace('#', '');
    const color2 = appObj.color2?.replace('#', '');
    if (color1) {
      iconSrc[
        app
      ] = `${iconSrcBase}/branding/icons?name=${app}&color1=${color1}${
        color2 && `&color2=${color2}`
      }`;
    } else {
      iconSrc[app] = '';
    }
  });
  return iconSrc;
};
export default iconSrcGenerator;
