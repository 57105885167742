import { NvButton } from 'nv-react-components-v2';
import React, { useEffect, useState } from 'react';
import Joyride, {
  CallBackProps,
  STATUS,
  EVENTS,
  ACTIONS,
  Step,
} from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import { app, productTour } from '../../../store/actions';
import { appSelector, productTourSelector } from '../../../store/selectors';
import { AppState } from '../../../store/app/interfaces';
import { ThunkDispatch } from '../../../types/ThunkDispatch';
import styles from './styles.module.scss';

const commonStepProps: Partial<Step> = {
  disableBeacon: true,
  hideCloseButton: true,
};
const STEPTITLES = {
  RESELLERID: 'Reseller ID',
  EDIT: 'Editing Controls',
  OVERVIEW: 'Overview',
  MOCKUP: 'Mockups',
  PUBLISH: 'Publish',
};

const ProductTour = () => {
  const dispatch: ThunkDispatch = useDispatch();
  const { resellerIds, activeComponent } = useSelector(appSelector);
  const { storageDocId, branding: tourState } = useSelector(
    productTourSelector,
  );

  const [showNotification, setShowNotification] = useState(false);

  const hasStartedTour = !!storageDocId;

  useEffect(() => {
    (async () => {
      const res = await dispatch(productTour.fetchProductTour());
      setShowNotification(!res?.branding?.complete);
    })();
  }, [dispatch]);

  const dismissNotification = () => {
    setShowNotification(false);
    dispatch(
      productTour.saveBrandingTour({
        ...tourState,
        complete: true,
      }),
    );
  };

  const steps = React.useMemo(() => {
    let _steps: Step[] = [];
    if (resellerIds.length < 1) {
      _steps.push({
        target: `#product-tour-select-reseller`,
        title: STEPTITLES.RESELLERID,
        content:
          'Type in the ID number of the reseller you wish to update, then hit the download button to load their information',
      });
    }
    _steps.push(
      {
        target: "[class*='slat_container']#Brand",
        title: STEPTITLES.EDIT,
        content:
          'Use our editing controls to make changes to your brand information',
      },
      {
        target: '#product-tour-preview',
        title: STEPTITLES.OVERVIEW,
        content:
          'You can see an overview of your changes and brand information here',
      },
      {
        target: '#product-tour-mockup',
        title: STEPTITLES.MOCKUP,
        content:
          'Choose one of our app mockups to see how your apps would look in real time',
      },
      {
        target: '#product-tour-publish',
        title: STEPTITLES.PUBLISH,
        content:
          'When you are ready, hit the publish button to see your changes go live!',
      },
    );
    _steps = _steps.map(step => ({ ...step, ...commonStepProps }));
    return _steps;
  }, [resellerIds]);

  const stepTitleToActiveComponentMap: {
    [key: string]: AppState['activeComponent'];
  } = {
    [STEPTITLES.RESELLERID]: 'sidebar',
    [STEPTITLES.EDIT]: 'sidebar',
    [STEPTITLES.OVERVIEW]: 'preview',
    [STEPTITLES.MOCKUP]: 'preview',
    [STEPTITLES.PUBLISH]: 'sidebar',
  };

  const handleStepChange = (data: CallBackProps) => {
    const { run, complete, stepIndex } = tourState;
    const { status, action, type, index } = data;
    const stopRunning = [STATUS.FINISHED, STATUS.SKIPPED] as string[];
    if (stopRunning.includes(status) || action === ACTIONS.CLOSE) {
      if (run) {
        dispatch(
          productTour.saveBrandingTour({
            complete: complete || status === STATUS.FINISHED,
            stepIndex: stepIndex % steps.length,
            run: false,
          }),
        );
      }
      return;
    }

    const goNext = action === ACTIONS.NEXT && type === EVENTS.STEP_AFTER;
    const goPrev = action === ACTIONS.PREV && type === EVENTS.STEP_AFTER;

    const _updateStepIndex = (delayForAnimation: boolean = false) => {
      if (delayForAnimation) {
        setTimeout(() => {
          if (goNext) {
            dispatch(productTour.setBrandingTourStep(stepIndex + 1));
          }
          if (goPrev) {
            dispatch(productTour.setBrandingTourStep(stepIndex - 1));
          }
        }, 300);
      } else {
        if (goNext) {
          dispatch(productTour.setBrandingTourStep(stepIndex + 1));
        }
        if (goPrev) {
          dispatch(productTour.setBrandingTourStep(stepIndex - 1));
        }
      }
    };

    if (goNext || goPrev) {
      const nextStep = steps[goNext ? index + 1 : index - 1];
      const title = nextStep?.title || '';
      const nextComponent = stepTitleToActiveComponentMap[title as string];
      if (nextComponent !== activeComponent) {
        dispatch(app.setActiveComponent(nextComponent));
        _updateStepIndex(true);
      } else {
        _updateStepIndex();
      }
    }
  };

  const startTour = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowNotification(false);
    const currentTitle = steps[tourState.stepIndex].title as string;
    const firstComponent = stepTitleToActiveComponentMap[currentTitle];
    if (firstComponent !== activeComponent) {
      dispatch(app.setActiveComponent(firstComponent));
      setTimeout(() => {
        dispatch(productTour.setBrandingTourRun(true));
      }, 300);
    } else {
      dispatch(productTour.setBrandingTourRun(true));
    }
  };
  return (
    <>
      <div style={{ display: 'none' }}>
        <Joyride
          run={tourState.run}
          steps={steps}
          continuous
          showSkipButton
          spotlightClicks
          disableOverlayClose
          disableScrollParentFix
          showProgress
          styles={{
            options: {
              zIndex: 10000,
              primaryColor: 'var(--accent)',
              beaconSize: 24,
            },
          }}
          locale={{
            next: <span className={styles.font_override}>Next</span>,
            skip: <span className={styles.font_override}>Skip</span>,
            last: <span className={styles.font_override}>Done</span>,
          }}
          callback={handleStepChange}
          stepIndex={tourState.stepIndex}
        />
      </div>

      {showNotification && (
        <aside className={styles.tour_notification}>
          {hasStartedTour
            ? 'Would you like to continue your tour?'
            : 'Would you like a quick tour of Simwood Partner Branding editor?'}
          <div className={styles.tour_notification_buttons}>
            <NvButton onClick={dismissNotification}>
              <span className={styles.tour_notification_start}>No</span>
            </NvButton>
            <NvButton kind="secondary" onClick={startTour}>
              <span className={styles.tour_notification_start}>Yes</span>
            </NvButton>
          </div>
        </aside>
      )}
    </>
  );
};

export default ProductTour;
