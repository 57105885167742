import React from 'react';
import styles from './styles.module.scss';
import Tooltip from '../../Tooltip';
import { Props } from './interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const SlatInput: React.FC<Props> = ({
  children,
  label,
  required = false,
  tooltip,
}): JSX.Element => {
  return (
    <div className={styles.input_container}>
      <span className={styles.input_label}>
        {label} {required && <i className={styles.required}> *Required</i>}
        {tooltip && (
          <Tooltip content={tooltip}>
            <div className={styles.tooltip}>
              <FontAwesomeIcon icon={faQuestionCircle} />
            </div>
          </Tooltip>
        )}
      </span>
      {children}
    </div>
  );
};
export default React.memo(SlatInput);
