import { tooltips } from '../actionTypes';
import { TooltipState, TooltipAction } from './interfaces';
import { Reducer } from 'redux';
export const initialTooltipState: TooltipState = {
  fetching: false,
  publishing: false,
  keyedByApp: {},
  activeApp: 'manager',
  original: {},
  modified: {},
  cumulativeTooltipNodesById: {},
};

const fetchingTooltips = (
  state: TooltipState = initialTooltipState,
): TooltipState => {
  return {
    ...state,
    fetching: true,
  };
};
const fetchTooltips = (
  state: TooltipState = initialTooltipState,
  payload: TooltipAction['payload'],
): TooltipState => {
  return {
    ...state,
    keyedByApp: payload.keyedByApp || state.keyedByApp,
    original: payload.keyedByApp?.[state.activeApp] || {},
    modified: {},
    fetching: false,
  };
};

const updateTooltips = (
  state: TooltipState = initialTooltipState,
  payload: TooltipAction['payload'],
): TooltipState => {
  return {
    ...state,
    modified: {
      ...state.modified,
      ...payload.modified,
    },
  };
};

const revertTooltips = (
  state: TooltipState = initialTooltipState,
  payload: TooltipAction['payload'],
): TooltipState => {
  // remove elements provided in payload from modified
  // this will cause them to use original
  const newModified: TooltipState['modified'] = {
    ...state.modified,
  };
  for (const key in payload.modified) {
    delete newModified[key];
  }
  return {
    ...state,
    modified: newModified,
  };
};

const publishingTooltips = (
  state: TooltipState = initialTooltipState,
): TooltipState => {
  return {
    ...state,
    publishing: true,
  };
};
const publishTooltips = (
  state: TooltipState = initialTooltipState,
  payload: TooltipAction['payload'],
): TooltipState => {
  const original = payload.original || state.original;
  const keyedByApp = { ...state.keyedByApp, [state.activeApp]: original };
  return {
    ...state,
    keyedByApp,
    original,
    modified: payload.original ? {} : state.modified,
    publishing: false,
  };
};

const changeTooltipApp = (
  state: TooltipState = initialTooltipState,
  payload: TooltipAction['payload'],
): TooltipState => {
  const activeApp = payload.activeApp || state.activeApp;
  return {
    ...state,
    activeApp,
    original: state.keyedByApp[activeApp] || {},
    modified: {},
    cumulativeTooltipNodesById: {},
  };
};

const accumulateTooltipNodes = (
  state: TooltipState = initialTooltipState,
  payload: TooltipAction['payload'],
): TooltipState => {
  return {
    ...state,
    cumulativeTooltipNodesById: {
      ...state.cumulativeTooltipNodesById,
      ...payload.cumulativeTooltipNodesById,
    },
  };
};

const reducer: Reducer<TooltipState, TooltipAction> = (
  state: TooltipState = initialTooltipState,
  action: TooltipAction,
) => {
  if (!action) {
    return { ...state };
  }
  switch (action.type) {
    case tooltips.fetching:
      return fetchingTooltips(state);
    case tooltips.fetch:
      return fetchTooltips(state, action.payload);
    case tooltips.update:
      return updateTooltips(state, action.payload);
    case tooltips.publishing:
      return publishingTooltips(state);
    case tooltips.publish:
      return publishTooltips(state, action.payload);
    case tooltips.revert:
      return revertTooltips(state, action.payload);
    case tooltips.changeApp:
      return changeTooltipApp(state, action.payload);
    case tooltips.accumulateTooltipNodes:
      return accumulateTooltipNodes(state, action.payload);
    default:
      return { ...state };
  }
};
export default reducer;
