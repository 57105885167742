import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { app } from '../../../store/actions';

const Logout: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(app.logout());
    history.push('/login');
  }, [dispatch, history]);

  return null;
};
export default Logout;
