import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styles from './styles.module.scss';
const Contacts = (): JSX.Element => {
  const exampleCalls = [
    { name: 'Fred Bloggs', number: '07918371957' },
    { name: 'John Doe', number: '07935193713' },
    { name: 'Natalie Baker', number: '07936592746' },
  ];
  return (
    <>
      <header className={styles.header}>
        <h1>Contacts</h1>
      </header>
      <section className={styles.main}>
        <div className={styles.contacts_container}>
          <input
            type="text"
            className={styles.search}
            placeholder="Search Contacts"
          />
          <ul className={styles.contacts_list}>
            {exampleCalls.map(({ name, number }) => (
              <li className={styles.contact_item} key={name + number}>
                <div className={styles.profile}>{name[0]}</div>
                <span className={styles.name}>{name}</span>
                <span className={styles.number}>{number}</span>
                <div className={styles.call}>
                  <FontAwesomeIcon icon={faPhoneAlt} />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </>
  );
};

export default Contacts;
