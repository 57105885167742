import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tooltips } from '../../../store/actions';
import setTitle from '../../../helpers/setTitle';
import AppShell from '../../templates/AppShell';
import TooltipSidebar from '../../organisms/TooltipSidebar';
import TooltipAppFrame from '../../organisms/TooltipAppFrame';
import { tooltipIsDirtySelector } from '../../../store/selectors';
// import styles from './styles.module.scss';

const TooltipPage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const tooltipDirty = useSelector(tooltipIsDirtySelector);

  useEffect(() => {
    setTitle('Customise Tooltips');
    dispatch(tooltips.fetchTooltips());
  }, [dispatch]);

  return (
    <AppShell
      sidebar={<TooltipSidebar />}
      content={<TooltipAppFrame />}
      dirtyGuard={tooltipDirty}
    />
  );
};

export default TooltipPage;
