import { icons } from '../actionTypes';
import { IconModel } from './interfaces';

export const setColors = (appIcons: Partial<IconModel>) => {
  // icons : {manager: color1: ?, color2: ?} | {analytics: color1: ?, color2: ?} | {communicator: color1: ?, color2: ?}
  return {
    type: icons.setColors,
    payload: { ...appIcons },
  };
};
export const revertIcons = () => {
  return {
    type: icons.revertIcons,
  };
};
