import React, { useState, useRef, useEffect } from 'react';
import styles from './styles.module.scss';
import { NvButton } from 'nv-react-components-v2';
import { Props } from './interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUndo,
  faCheck,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';

const Slat: React.FC<Props> = ({
  children,
  icon,
  title,
  cancelOnClick = () => {},
  isInvalid = '',
}): JSX.Element => {
  // Lets have the brand slat open when the app loads
  const [isSlatOpen, setSlatOpen] = useState(title === 'Brand');

  const thisSlat = useRef<HTMLDivElement>(null);

  /**
   * Handles clickOutside events
   * @param e
   */
  const handleClickOutside = React.useCallback((e: Event) => {
    if (thisSlat && !thisSlat.current?.contains(e.target as Node)) {
      setSlatOpen(false);
    }
  }, []);

  useEffect(() => {
    if (isSlatOpen && thisSlat) {
      // When slat is open, add eventlistener to its container
      thisSlat?.current?.parentNode?.addEventListener(
        'click',
        handleClickOutside,
      );
    }
    if (!isSlatOpen && thisSlat) {
      // When slat is closed, remove eventlistener from container
      thisSlat.current?.parentNode?.removeEventListener(
        'click',
        handleClickOutside,
      );
    }
  }, [handleClickOutside, isSlatOpen]);

  return (
    <div
      className={`${styles.slat_container} ${isSlatOpen && styles.show}`}
      ref={thisSlat}
      id={title}
    >
      <button
        className={styles.slat_header}
        onClick={() => {
          setSlatOpen(!isSlatOpen);
        }}
      >
        <span className={styles.slat_title}>{title}</span>
        <div className={styles.slat_right}>
          {!!isInvalid && (
            <span
              className={`${styles.missing_fields} ${
                isSlatOpen && styles.muted
              }`}
            >
              <FontAwesomeIcon icon={faExclamationCircle} />
            </span>
          )}
          <div className={styles.slat_icon}>{icon}</div>
        </div>
      </button>
      {isSlatOpen && (
        <>
          <div className={styles.slat_content}>{children}</div>
          <div className={styles.slat_footer}>
            <NvButton
              kind="danger"
              size="small"
              onClick={cancelOnClick}
              inverted
              rightIcon={<FontAwesomeIcon icon={faUndo} />}
            >
              Revert Changes
            </NvButton>
            <NvButton
              kind="primary"
              size="small"
              onClick={() => {
                setSlatOpen(!isSlatOpen);
              }}
              rightIcon={<FontAwesomeIcon icon={faCheck} />}
            >
              Done
            </NvButton>
          </div>
        </>
      )}
    </div>
  );
};
export default React.memo(Slat);
