import { FeatureState, FeatureAction } from './interfaces';
import { features } from '../actionTypes';
import { Reducer } from 'redux';

const setProvisioningEnabled = (
  state: FeatureState,
  payload: FeatureAction['payload'],
) => {
  return {
    ...state,
    modified: {
      ...state.modified,
      provisioningEnabled: !!payload.provisioningEnabled,
    },
  };
};

const revertFeatures = (state: FeatureState) => {
  return {
    ...state,
    modified: { ...state.original },
  };
};

export const initialFeatureState: FeatureState = {
  original: { provisioningEnabled: false },
  modified: {
    provisioningEnabled: false,
  },
};

const reducer: Reducer<FeatureState, FeatureAction> = (
  state: FeatureState = initialFeatureState,
  action: FeatureAction,
) => {
  if (!action) {
    return { ...{}, ...state };
  }
  switch (action.type) {
    case features.setProvisioningEnabled:
      return setProvisioningEnabled(state, action.payload);
    case features.revertFeatures:
      return revertFeatures(state);
    default:
      return { ...state };
  }
};
export default reducer;
