import { fonts } from '../actionTypes';
import { FontState, FontAction } from './interfaces';
import { Reducer } from 'redux';

// functions
const selectFont = (state: FontState, payload: FontAction['payload']) => {
  return {
    ...state,
    modified: { ...state.modified, selectedFont: payload.selectedFont },
  };
};
const selectFamily = (state: FontState, payload: FontAction['payload']) => {
  return {
    ...state,
    modified: {
      ...state.modified,
      fontFamily: payload.fontFamily ?? state.modified.fontFamily,
    },
  };
};

const setFontImportUrl = (state: FontState, payload: FontAction['payload']) => {
  return {
    ...state,
    modified: {
      ...state.modified,
      fontImportUrl: payload.fontImportUrl ?? state.modified.fontImportUrl,
    },
  };
};
const selectTabularFont = (
  state: FontState,
  payload: FontAction['payload'],
) => {
  return {
    ...state,
    modified: {
      ...state.modified,
      selectedTabularFont: payload.selectedTabularFont,
    },
  };
};
const selectTabularFamily = (
  state: FontState,
  payload: FontAction['payload'],
) => {
  return {
    ...state,
    modified: {
      ...state.modified,
      tabularFontFamily:
        payload.tabularFontFamily ?? state.modified.tabularFontFamily,
    },
  };
};

const setTabularFontImportUrl = (
  state: FontState,
  payload: FontAction['payload'],
) => {
  return {
    ...state,
    modified: {
      ...state.modified,
      tabularFontImportUrl:
        payload.tabularFontImportUrl ?? state.modified.tabularFontImportUrl,
    },
  };
};

const fetchGoogleFonts = (state: FontState, payload: FontAction['payload']) => {
  return { ...state, googleFonts: payload.googleFonts || state.googleFonts };
};

const setFontWeightLight = (
  state: FontState,
  payload: FontAction['payload'],
) => {
  const { fontWeights } = state.modified;
  return {
    ...state,
    modified: {
      ...state.modified,
      fontWeights: {
        ...fontWeights,
        light: payload.fontWeightLight,
      },
    },
  };
};
const setFontWeightRegular = (
  state: FontState,
  payload: FontAction['payload'],
) => {
  const { fontWeights } = state.modified;
  return {
    ...state,
    modified: {
      ...state.modified,
      fontWeights: {
        ...fontWeights,
        regular: payload.fontWeightRegular,
      },
    },
  };
};
const setFontWeightSemibold = (
  state: FontState,
  payload: FontAction['payload'],
) => {
  const { fontWeights } = state.modified;
  return {
    ...state,
    modified: {
      ...state.modified,
      fontWeights: {
        ...fontWeights,
        semibold: payload.fontWeightSemibold,
      },
    },
  };
};
const setFontWeightBold = (
  state: FontState,
  payload: FontAction['payload'],
) => {
  const { fontWeights } = state.modified;
  return {
    ...state,
    modified: {
      ...state.modified,
      fontWeights: {
        ...fontWeights,
        bold: payload.fontWeightBold,
      },
    },
  };
};
const revertFonts = (state: FontState) => {
  return {
    ...state,
    modified: state.original,
  };
};
export const initialFontState: FontState = {
  googleFonts: [],
  original: {
    fontFamily: ['Open Sans'],
    fontImportUrl: '',
    tabularFontFamily: [''],
    tabularFontImportUrl: '',
    fontWeights: {
      light: '300',
      regular: '400',
      semibold: '500',
      bold: '700',
    },
  },
  modified: {
    fontFamily: ['Open Sans'],
    fontImportUrl: '',
    tabularFontFamily: [''],
    tabularFontImportUrl: '',
    fontWeights: {
      light: '300',
      regular: '400',
      semibold: '500',
      bold: '700',
    },
  },
};

// reducer
const reducer: Reducer<FontState, FontAction> = (
  state: FontState = initialFontState,
  action: FontAction,
) => {
  if (!action) {
    return { ...{}, ...state };
  }
  switch (action.type) {
    case fonts.fetchGoogleFonts:
      return fetchGoogleFonts(state, action.payload);
    case fonts.selectFont:
      return selectFont(state, action.payload);
    case fonts.selectFamily:
      return selectFamily(state, action.payload);
    case fonts.setFontImportUrl:
      return setFontImportUrl(state, action.payload);
    case fonts.selectTabularFont:
      return selectTabularFont(state, action.payload);
    case fonts.selectTabularFamily:
      return selectTabularFamily(state, action.payload);
    case fonts.setTabularFontImportUrl:
      return setTabularFontImportUrl(state, action.payload);
    case fonts.setFontWeightLight:
      return setFontWeightLight(state, action.payload);
    case fonts.setFontWeightRegular:
      return setFontWeightRegular(state, action.payload);
    case fonts.setFontWeightSemibold:
      return setFontWeightSemibold(state, action.payload);
    case fonts.setFontWeightBold:
      return setFontWeightBold(state, action.payload);
    case fonts.revertFonts:
      return revertFonts(state);
    default:
      return { ...state };
  }
};
export default reducer;
