import React from 'react';
import { useHistory } from 'react-router-dom';

import { NvErrorPage } from 'nv-react-components-v2';
import setTitle from '../../../helpers/setTitle';

const PageNotFound: React.FC = (): JSX.Element | null => {
  const history = useHistory();
  setTitle('Page not found');
  return (
    <NvErrorPage
      message="Page not found"
      title="404"
      buttonOnClick={() => {
        history.push('/login');
      }}
      buttonText="Go home"
    />
  );
};
export default PageNotFound;
