import { productTour } from '../actionTypes';
import { ProductTourAction, ProductTourState } from './interfaces';
import { Reducer } from 'redux';
export const initialProductTourState: ProductTourState = {
  branding: {
    run: false,
    stepIndex: 0,
    complete: false,
  },
};

const fetchProductTour = (
  state: ProductTourState = initialProductTourState,
  payload: ProductTourAction['payload'],
): ProductTourState => {
  return {
    ...state,
    storageDocId: payload.storageDocId || '',
    branding: {
      ...state.branding,
      ...payload.branding,
    },
  };
};

const setBrandingTourRun = (
  state: ProductTourState = initialProductTourState,
  payload: ProductTourAction['payload'],
): ProductTourState => {
  return {
    ...state,
    branding: {
      ...state.branding,
      run: payload.branding?.run ?? state.branding.run,
    },
  };
};
const setBrandingTourStep = (
  state: ProductTourState = initialProductTourState,
  payload: ProductTourAction['payload'],
): ProductTourState => {
  return {
    ...state,
    branding: {
      ...state.branding,
      stepIndex: payload.branding?.stepIndex ?? state.branding.stepIndex,
    },
  };
};

const saveBrandingTour = (
  state: ProductTourState = initialProductTourState,
  payload: ProductTourAction['payload'],
): ProductTourState => {
  return {
    ...state,
    storageDocId: payload.storageDocId || state.storageDocId,
    branding: {
      ...state.branding,
      ...payload.branding,
    },
  };
};
const reducer: Reducer<ProductTourState, ProductTourAction> = (
  state: ProductTourState = initialProductTourState,
  action: ProductTourAction,
) => {
  if (!action) {
    return { ...state };
  }
  switch (action.type) {
    case productTour.fetchProductTour:
      return fetchProductTour(state, action.payload);
    case productTour.branding.setRun:
      return setBrandingTourRun(state, action.payload);
    case productTour.branding.setStep:
      return setBrandingTourStep(state, action.payload);
    case productTour.branding.saveTour:
      return saveBrandingTour(state, action.payload);
    default:
      return { ...state };
  }
};
export default reducer;
