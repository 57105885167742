import { useCallback, useEffect, useState } from 'react';
import { THEMES } from '../helpers/constants';

export const useDarkMode = () => {
  /**
   * Toggle Dark Mode
   */
  //
  const initialDarkMode = () => {
    // * first check localStorage
    const inStorage = localStorage.getItem('branding-interface-isDarkMode');
    if (inStorage) return inStorage === 'true';
    // * if we can't check system theme, default to light theme
    if (!('matchMedia' in window)) return false;
    // * otherwise use system theme
    const queryDarkMode = window.matchMedia('(prefers-color-scheme: dark)');
    if (queryDarkMode.addEventListener) {
      queryDarkMode.addEventListener('change', qDM => {
        setIsDarkMode(qDM.matches);
      });
    } else {
      queryDarkMode.addListener(qDM => {
        setIsDarkMode(qDM.matches);
      });
    }

    return queryDarkMode.matches;
  };
  const [isDarkMode, setIsDarkMode] = useState(initialDarkMode());

  const setDarkMode = useCallback(() => {
    const root = document.documentElement;
    const body = document.body;

    const chosenTheme = isDarkMode ? THEMES.DARK : THEMES.LIGHT;
    Object.entries(chosenTheme).forEach(([varName, value]) => {
      root.style.setProperty('--' + varName, value);
      body.style.setProperty('--' + varName, value);
    });
  }, [isDarkMode]);

  useEffect(() => {
    setDarkMode();
  }, [setDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    localStorage.setItem(
      'branding-interface-isDarkMode',
      !isDarkMode ? 'true' : 'false',
    );
  };

  return [isDarkMode, toggleDarkMode] as const;
};
