import { FontState, FontModel } from '../store/fonts/interfaces';
export const fontArrayToString = (fontArr: string[]) =>
  fontArr.map(font => `'${font}'`).join(', ');
export const fontStringToArray = (fontStr: string) => {
  // * 'Open Sans' , sans-serif -> [ 'Open Sans', sans-serif ]
  let fontArr = fontStr.split(',').map(font => font.trim());
  // * [ 'Open Sans' , sans-serif ] -> [ Open Sans, sans-serif ]
  fontArr = fontArr.map(font => font.replace(/^"(.*)"$/, '$1')); // remove double quotes
  fontArr = fontArr.map(font => font.replace(/^'(.*)'$/, '$1')); // remove single quotes
  return fontArr;
};

const deDupFontWeights = (fontWeights: FontModel['fontWeights']) => {
  const fontWeightValues = Object.values(fontWeights);
  const deDupedFontWeights = new Set(fontWeightValues);
  return Array.from(deDupedFontWeights);
};

export const getFontImportUrl = (
  fonts: FontState['modified'],
  type: 'regular' | 'tabular',
) => {
  const fontFamily =
    type === 'regular' ? fonts.fontFamily : fonts.tabularFontFamily;
  const fontWeights = fonts.fontWeights;
  const deDupedFontWeights = deDupFontWeights(fontWeights);
  const fontImportUrl = [];
  fontImportUrl.push('https://fonts.googleapis.com/css?family=');
  fontImportUrl.push(fontFamily[0].replace(/ /g, '+'));
  fontImportUrl.push(':');
  fontImportUrl.push(deDupedFontWeights.join(','));
  // include italics for each weight, remove if not needed
  fontImportUrl.push(
    deDupedFontWeights.map(fontWeight => `,${fontWeight}i`).join(''),
  );
  fontImportUrl.push('&display=swap');
  return fontImportUrl.join('');
};
