export const tooltipFrame: {
  window: Window | null;
  appUrl: string;
  postMessage: (eventName: string, detail?: any) => void;
} = {
  window: null,
  appUrl: '',
  postMessage: function (eventName, detail) {
    if (!this.window)
      return console.warn('Could not post message as window is null');
    if (!this.appUrl) return console.warn('appUrl not specified');
    this.window.postMessage(
      { event: eventName, detail, source: 'branding-interface' },
      this.appUrl,
    );
  },
};
