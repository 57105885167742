import React from 'react';

import { NvButton } from 'nv-react-components-v2';
import { Props } from './interfaces';
import styles from './styles.module.scss';

const Tabs = <T extends string>({
  tabs,
  onTabChange,
  selectedTab,
  disabledTabs = [],
  dense = false,
}: Props<T>): JSX.Element => {
  return (
    <div className={`${styles.tabs} ${dense && styles.dense}`}>
      <div className={styles.tab_scroller}>
        {tabs.map(tab => {
          if (disabledTabs.includes(tab)) return null;
          return (
            <NvButton
              inverted
              className={`${styles.tab_item} ${
                selectedTab === tab && styles.selected
              }`}
              key={tab}
              onClick={e => {
                onTabChange(tab);
                (e.target as Element).scrollIntoView({
                  behavior: 'smooth',
                  block: 'nearest',
                  inline: 'center',
                });
              }}
            >
              {tab[0].toUpperCase() + tab.slice(1)}
            </NvButton>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(Tabs);
