import React, { CSSProperties, useState } from 'react';

import {
  NvButton,
  NvInput,
  NvLoader,
  NvSelectDropdown,
} from 'nv-react-components-v2';
import { Props } from './interfaces';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { app } from '../../../store/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSave } from '@fortawesome/free-solid-svg-icons';
import { appSelector } from '../../../store/selectors';

const SideBar: React.FC<Props> = ({
  children,
  publishOnClick,
  publishing = false,
  resellerOnSubmit,
  resellerDataLoading = false,
}): JSX.Element => {
  const dispatch = useDispatch();
  /**
   * Get Variables from store
   */
  const { resellerIds } = useSelector(appSelector);
  const { currentReseller } = useSelector(appSelector, () => true);

  const [resellerId, setResellerId] = useState(currentReseller);
  /**
   * Handle Reseller Id Change
   * @param e
   */
  const resellerSelectOnChange = (
    e: { label: string; value: string }[],
  ): void => {
    const reseller = parseInt(e[0]?.value);
    setCurrentReseller(reseller);
  };

  const setCurrentReseller = (resellerId: number) => {
    dispatch(app.setCurrentReseller(resellerId));
    resellerOnSubmit(resellerId);
  };

  return (
    <div className={styles.sidebar}>
      {resellerIds.length !== 1 && (
        <div className={styles.user_info}>
          {resellerIds.length > 1 && (
            <span className={styles.user_info_item}>
              <span className={styles.user_info_title}>Reseller Id</span>
              <NvSelectDropdown
                options={resellerIds.map(rid => ({
                  label: `${rid}`,
                  value: `${rid}`,
                }))}
                type="inline"
                defaultValue={resellerIds[0].toString()}
                className={styles.user_info_reseller_select}
                isClearable={false}
                onChange={resellerSelectOnChange}
                color={{
                  text: 'var(--copy)',
                }}
                disabled={resellerDataLoading}
                selectStyles={{
                  menu: (base: CSSProperties) => ({
                    ...base,
                    zIndex: 2,
                  }),
                }}
              />
            </span>
          )}
          {resellerIds.length < 1 && (
            <span
              className={styles.user_info_item}
              id="product-tour-select-reseller"
            >
              <span className={styles.user_info_title}>Load Reseller</span>
              <div className={styles.user_info_reseller_input}>
                <NvInput
                  disabled={resellerDataLoading}
                  type="number"
                  size="small"
                  value={resellerId === -1 ? '' : resellerId.toString()}
                  onChange={e => setResellerId(parseInt(e.target.value))}
                  color={{
                    background: 'var(--baseDark)',
                    text: 'var(--copy)',
                  }}
                  inputProps={{
                    min: 0,
                    onKeyDown: e => {
                      if (e.key === 'Enter') {
                        setCurrentReseller(resellerId);
                      }
                    },
                  }}
                  placeholder="ID"
                />

                <NvButton
                  disabled={resellerDataLoading}
                  size="small"
                  mini
                  onClick={() => {
                    setCurrentReseller(resellerId);
                  }}
                  leftIcon={<FontAwesomeIcon icon={faDownload} />}
                />
              </div>
            </span>
          )}
        </div>
      )}
      {children}
      <div className={styles.publish_button} id="product-tour-publish">
        <NvButton
          onClick={publishOnClick}
          rightIcon={
            !publishing ? (
              <FontAwesomeIcon icon={faSave} />
            ) : (
              <NvLoader size="small" color="white" />
            )
          }
          disabled={publishing}
        >
          {!publishing ? 'Publish Changes' : 'Publishing Changes'}
        </NvButton>
      </div>
    </div>
  );
};

export default React.memo(SideBar);
