import React, { useEffect } from 'react';
import Slat from '../../atoms/Slat';
import SlatInput from '../../atoms/SlatBlocks/SlatInput';
import SlatHeader from '../../atoms/SlatBlocks/SlatHeader';
import Tooltip from '../../atoms/Tooltip';
import { Props } from './interfaces';
import { faIcons } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';
import { NvInput } from 'nv-react-components-v2';
import { useSelector, useDispatch } from 'react-redux';
import { IconModel } from '../../../store/icons/interfaces';
import { icons, app } from '../../../store/actions';
import objectIsEqual from '../../../helpers/isEqual';
import iconSrcGenerator from '../../../helpers/iconSrcGenerator';
import {
  modifiedColorsSelector,
  modifiedIconsSelector,
  validitySelector,
} from '../../../store/selectors';

const IconSlat: React.FC<Props> = (): JSX.Element => {
  const dispatch = useDispatch();
  const appIcons = useSelector(modifiedIconsSelector);
  const { primary, secondary } = useSelector(modifiedColorsSelector);
  const { icons: isSlatValid } = useSelector(validitySelector);

  const appArray = Object.keys(appIcons).sort();

  useEffect(() => {
    const invalidIcons = Object.values(appIcons).filter(app => !app.color1);
    const valid = invalidIcons.length === 0;
    if (valid === isSlatValid) return;
    dispatch(app.updateValidity({ icons: valid }));
  }, [dispatch, isSlatValid, appIcons]);

  const validator = (value: string, newIcons: Partial<IconModel>): string => {
    if (
      !value.match(/^#?([\da-fA-F]{2})([\da-fA-F]{2})([\da-fA-F]{2})$/g) &&
      value !== ''
    ) {
      return 'Hex Code Expected e.g. (#)FFFFFF';
    }
    // validation has passed, but check if input is new
    /** i.e. manager | analytics | communicator */
    const iconToChange = Object.keys(newIcons)[0];
    !objectIsEqual(newIcons[iconToChange], appIcons[iconToChange]) &&
      dispatch(icons.setColors(newIcons));
    return '';
  };

  /**
   * Update all icons to use a combination of their primary or secondary color
   * @param color
   */
  const inheritColor = React.useCallback(
    (color1: string, color2: string = '') => () => {
      const newColors = {
        color1,
        color2,
      };
      let newIcons: IconModel = {};
      appArray.forEach(appName => {
        newIcons[appName] = {
          ...appIcons[appName],
          ...newColors,
        };
      });
      dispatch(icons.setColors(newIcons));
    },
    [appIcons, dispatch, appArray],
  );

  const inputFields = (appName: string) => {
    const appObj = appIcons[appName];
    /**
     * For rendering an icon within the slat
     */
    const iconSrc: { [x: string]: string } = iconSrcGenerator(appIcons);
    return (
      <React.Fragment key={appName}>
        <SlatHeader title={appName + ' Icon'}>
          {iconSrc[appName] ? (
            <img
              src={iconSrc[appName]}
              alt="icon"
              className={styles.product_icon}
            />
          ) : (
            <img
              src={`https://my.nimvelo.com/branding/icons?name=${appName}&color1=aaaaaa`}
              alt="icon"
              className={styles.product_icon}
              style={{ opacity: '0.5' }}
            />
          )}
        </SlatHeader>
        <SlatInput label="Color 1">
          <NvInput
            size="standard"
            value={appObj.color1}
            validatorFunction={value =>
              validator(value, {
                // e.g. manager: {
                [appName]: {
                  ...appObj, // e.g. ...manager
                  color1: value,
                },
              })
            }
            validateTrigger="onChange"
            color={{
              background: 'var(--baseLight)',
              text: 'var(--copy)',
              borderFocus: `${appObj.color1}`,
              borderBlur: `${appObj.color1}`,
            }}
            placeholder={'#FFFFFF'}
            required
            useBrowserValidation
            reserveErrorSpace
          />
        </SlatInput>
        <SlatInput label="Color 2">
          <NvInput
            size="standard"
            value={appObj.color2}
            validateTrigger="onChange"
            validatorFunction={value =>
              validator(value, {
                [appName]: {
                  ...appObj,
                  color2: value,
                },
              })
            }
            color={{
              background: 'var(--baseLight)',
              text: 'var(--copy)',
              borderFocus: `${appObj.color2}`,
              borderBlur: `${appObj.color2}`,
            }}
            placeholder={'#FFFFFF'}
          />
        </SlatInput>
      </React.Fragment>
    );
  };

  const ColorPreset = React.useCallback(
    ({ color1, color2 = '' }: { color1: string; color2?: string }) => {
      let tooltip = '';
      if (color1 === primary) {
        tooltip += 'Primary';
      } else {
        tooltip += 'Secondary';
      }
      if (!color2) {
        tooltip += ` (Light ${tooltip})`;
      } else if (color2 === primary) {
        tooltip += ' (Primary)';
      } else {
        tooltip += ' (Secondary)';
      }
      return (
        <Tooltip content={tooltip}>
          <div
            className={styles.preset_color}
            onClick={inheritColor(color1, color2)}
          >
            <div
              className={styles.preset_color_one}
              style={{ background: color1 }}
            />
            <div
              className={styles.preset_color_two}
              style={{
                background: color2 || color1,
                opacity: color2 ? '' : '0.5',
              }}
            />
          </div>
        </Tooltip>
      );
    },
    [inheritColor, primary],
  );

  return (
    <Slat
      title="Icons"
      icon={<FontAwesomeIcon icon={faIcons} />}
      cancelOnClick={() => {
        dispatch(icons.revertIcons());
      }}
      isInvalid={!isSlatValid}
    >
      {(primary || secondary) && (
        <SlatInput
          label="Color Presets"
          tooltip="Automatically generated icon colors based on your chosen primary/ secondary colors"
        >
          <div className={styles.preset_color_wrapper}>
            {primary && <ColorPreset color1={primary} />}
            {secondary && <ColorPreset color1={secondary} />}
            {primary && secondary && (
              <>
                <ColorPreset color1={primary} color2={secondary} />
                <ColorPreset color1={secondary} color2={primary} />
              </>
            )}
          </div>
        </SlatInput>
      )}
      {appArray.map(inputFields)}
    </Slat>
  );
};
export default React.memo(IconSlat);
