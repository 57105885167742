import React from 'react';
import { useSelector } from 'react-redux';
import iconSrcGenerator from '../../../../helpers/iconSrcGenerator';
import styles from './styles.module.scss';
import { NvProgressBar } from 'nv-react-components-v2';
import {
  appSelector,
  modifiedBrandSelector,
  modifiedColorsSelector,
  modifiedFontsSelector,
  modifiedIconsSelector,
  modifiedLinksSelector,
  modifiedLogosSelector,
} from '../../../../store/selectors';

const Overview = (): JSX.Element => {
  const colors = useSelector(modifiedColorsSelector);
  const fonts = useSelector(modifiedFontsSelector);
  const links = useSelector(modifiedLinksSelector);
  const icons = useSelector(modifiedIconsSelector);
  const brand = useSelector(modifiedBrandSelector);
  const logos = useSelector(modifiedLogosSelector);

  const { brandingLoaded } = useSelector(appSelector);

  /**
   * Skeleton Text Wrapper
   * @param jsx
   */
  const skeletal = (jsx: JSX.Element | string) => {
    return brandingLoaded ? (
      jsx
    ) : (
      <NvProgressBar
        key={Math.random.toString()}
        progressPercentage="1"
        skeletonText
      />
    );
  };

  /**
   * Brand section
   */
  const brandPairs = () => {
    return (
      <>
        <div>Brand Name</div>
        <div>{skeletal(brand.brandName || '-')}</div>
        <div>Website</div>
        <div>
          {skeletal(
            brand.website ? (
              <a href={brand.website} target="_blank" rel="noopener noreferrer">
                {brand.website}
              </a>
            ) : (
              '-'
            ),
          )}
        </div>
      </>
    );
  };

  const logoPairs = () => {
    const LogoPair = ({ label, logo }: { label: string; logo: string }) => (
      <>
        <div>{label} Logo</div>
        <div
          style={{
            background: colors.topbarInverted ? 'white' : colors.secondary,
          }}
        >
          {logos.company.logoSrc ? (
            <img
              src={logo || logos.company.logoSrc}
              alt="logo"
              style={{
                height: '3em',
              }}
            />
          ) : (
            '-'
          )}
        </div>
      </>
    );
    return (
      <>
        <LogoPair label="Company" logo={logos.company.logoSrc} />
        <LogoPair label="Product" logo={logos.product.logoSrc} />
      </>
    );
  };

  const colorPairs = () => {
    const ColorPair = ({ label, color }: { label: string; color: string }) => (
      <>
        <div>{label}</div>
        <div
          style={{
            backgroundColor: color,
            height: '1.5em',
          }}
        >
          <span
            style={{
              backgroundColor: 'var(--baseDark)',
              padding: '3px',
            }}
          >
            {color}
          </span>
        </div>
      </>
    );
    return (
      <>
        <ColorPair label="Primary Color" color={colors.primary} />
        <ColorPair label="Secondary Color" color={colors.secondary} />
        <ColorPair label="Success Color" color={colors.success} />
        <ColorPair label="Danger Color" color={colors.danger} />
        <ColorPair label="Warning Color" color={colors.warning} />
        <ColorPair label="Info Color" color={colors.info} />
        <div>Topbar/ Login Inverted</div>
        <div>
          <span>{colors.topbarInverted ? 'Enabled' : 'Disabled'}</span>
        </div>
      </>
    );
  };

  /**
   * Links section
   */
  const linkPairs = () => {
    const html = [];
    const linkConfig = [
      // [ linkProperty, displayLabel]
      ['supportEmail', 'Support Email'],
      ['supportPhone', 'Support Phone'],
      ['termsUrl', 'Terms URL'],
      ['contactsUrl', 'Contacts URL'],
      ['refundsUrl', 'Refunds URL'],
      ['helpUrl', 'Help URL'],
      ['twitter', 'Twitter Name'],
      ['twitterUrl', 'Twitter URL'],
    ];
    for (const link in links) {
      const config = linkConfig.find(element => element[0] === link);
      if (config) {
        let title = config?.[1];
        const anchorTag = links[link] ? (
          <a
            key={`${title}-link`}
            href={links[link]}
            target="_blank"
            rel="noopener noreferrer"
          >
            {links[link]}
          </a>
        ) : (
          '-'
        );
        html.push(
          <React.Fragment key={`${title}-frag`}>
            <div key={title}>{title}</div>
            <div key={`${title}-link-container`}>{skeletal(anchorTag)}</div>
          </React.Fragment>,
        );
      }
    }
    return html;
  };

  /**
   * Fonts section
   */
  const fontPairs = () => {
    let html = [];
    html.push(
      <React.Fragment key="Font-Families">
        <div>Font Family</div>
        <div>{skeletal(fonts.fontFamily.join(',') || '-')}</div>
        <div>Font Import URL</div>
        <div>{skeletal(fonts.fontImportUrl || '-')}</div>
        {fonts.tabularFontFamily.join() && (
          <>
            <div>Tabular Font Family</div>
            <div>{skeletal(fonts.tabularFontFamily.join(',') || '-')}</div>
          </>
        )}
        {fonts.tabularFontImportUrl && (
          <>
            <div>Tabular Font Import URL</div>
            <div>{skeletal(fonts.tabularFontImportUrl || '-')}</div>
          </>
        )}
      </React.Fragment>,
    );
    for (const fontWeight in fonts.fontWeights) {
      html.push(
        <React.Fragment key={`${fontWeight}-frag`}>
          <div key={`${fontWeight}-title`}>
            Font Weight {fontWeight[0].toUpperCase() + fontWeight.slice(1)}
          </div>
          <div
            key={`${fontWeight}-text`}
            style={{ fontWeight: parseInt(fonts.fontWeights[fontWeight]) }}
          >
            {skeletal('All their equipment and instruments are alive.')}
          </div>
        </React.Fragment>,
      );
    }
    return html;
  };

  /**
   * Icons section
   */
  const iconPairs = () => {
    const iconSrc: { [key: string]: string } = iconSrcGenerator(icons);
    let html = [];
    for (let app in iconSrc) {
      const appName = app[0].toUpperCase() + app.slice(1);
      html.push(
        <React.Fragment key={`${appName}-frag`}>
          <div key={`${appName}-title`}>{appName} Icon</div>
          <div key={`${appName}-img-container`}>
            {iconSrc[app] ? (
              <img
                key={`${appName}-img`}
                src={iconSrc[app]}
                alt={`${appName} Icon`}
                className={styles.product_icon}
              />
            ) : (
              '-'
            )}
          </div>
        </React.Fragment>,
      );
    }
    return html;
  };

  return (
    <div className={styles.overview_app}>
      <span className={styles.overview_header}>Brand</span>
      {brandPairs()}
      <span className={styles.overview_header}>Logos</span>
      {logoPairs()}
      <span className={styles.overview_header}>Colors</span>
      {colorPairs()}
      <span className={styles.overview_header}>Fonts</span>
      {fontPairs()}
      <span className={styles.overview_header}>Links</span>
      {linkPairs()}
      <span className={styles.overview_header}>Icons</span>
      {iconPairs()}
    </div>
  );
};

export default Overview;
